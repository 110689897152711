// conversionId can refer to either conversionId (which refers to completed registration) or initiateCheckoutId
const trackLinkedInConversion = ({ partnerId, conversionId }) => {
  const imgElement = document.createElement("img");
  imgElement.innerHTML =
    /* html */
    `<img
      height="1"
      width="1"
      style="display:none;"
      alt=""
      src="https://dc.ads.linkedin.com/collect/?pid=${partnerId}&conversionId=${conversionId}&fmt=gif" />`;

  document.body.appendChild(imgElement);
};

export default trackLinkedInConversion;
