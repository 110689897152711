import { h } from "preact";
import i18n from "i18next";
import "./RedirectScreen.scss";
import { useEffect, useContext } from "preact/hooks";
import { Store } from "../../store";
import { trackInitiatedCheckout } from "../../tracking";

const DURATION_BEFORE_REDIRECTING_MS = 5000;

const RedirectScreen = ({ redirectUrl, customer }) => {
  const { state } = useContext(Store);
  useEffect(() => {
    const timer = setInterval(() => {
      window.location.href = redirectUrl;
    }, DURATION_BEFORE_REDIRECTING_MS);
    return () => clearInterval(timer);
  }, [redirectUrl]);

  const { tracking } = state;
  useEffect(() => {
    if (tracking.isInitialized) {
      trackInitiatedCheckout(customer);
    }
  }, [tracking.isInitialized]);

  return (
    <div className="redirect-screen__wrapper">
      <div className="redirect-screen__content">
        <div className="redirect-screen__spinner-wrapper">
          <div className="redirect-screen__spinner">
            <div
              className="redirect-screen__spinner-inner"
              style={{ color: customer?.customerColor }}
            />
          </div>
          <div className="redirect-screen__logo-wrapper">
            <div
              className="title-block__logo"
              style={{
                background: customer?.customerColor || null,
              }}
            >
              <div
                style={{
                  backgroundSize: customer?.customerColor ? "90%" : null,
                  borderRadius: customer?.customerColor ? "6px" : null,
                  backgroundImage: `url(${customer?.logoUrl})`,
                }}
              />
            </div>
          </div>
        </div>
        <h2 className="redirect-screen__heading">
          {i18n.t("redirect.pleaseWait")}
        </h2>
        <p>
          <span className="redirect-screen__text">
            {i18n.t("redirect.beingRedirected", {
              customerName: customer.name,
            })}
          </span>
        </p>
      </div>
    </div>
  );
};

export default RedirectScreen;
