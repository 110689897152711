import { h, Fragment } from "preact";
import { useState, useContext } from "preact/hooks";
import i18n from "i18next";
import * as Sentry from "@sentry/browser";
import { Store } from "../../store";
import { saveAvatar } from "../../actionCreators";
import { readFileAsBase64, isFacebookInAppBrowser } from "../../utils";

import AvatarVariant from "../AvatarVariant/AvatarVariant";
import UploadStorage from "../UploadStorage/UploadStorage";
import UploadGoogleDrive from "../UploadGoogleDrive/UploadGoogleDrive";
import UploadDropbox from "../UploadDropbox/UploadDropbox";
import LinkedInAuth from "../LinkedInAuth/LinkedInAuth";
import linkedInIconSvg from "../../../assets/images/linkedInIcon.svg";
import facebookIconSvg from "../../../assets/images/facebookIcon.svg";
import dropboxIconSvg from "../../../assets/images/dropboxIcon.svg";
import googleDriveIconSvg from "../../../assets/images/googleDriveIcon.svg";
import "./AvatarPopup.scss";

const AvatarPopup = ({
  closePopupWithTransition,
  updateAvatar,
  isForm = false,
}) => {
  const { state, dispatch } = useContext(Store);
  const { facebookUserId, linkedInUrl } = state.application;
  const { avatars, confirmedAvatar } = state.avatar;
  const [selectedAvatar, setSelectedAvatar] = useState(confirmedAvatar);
  const [showFacebookPhotos] = useState(false);
  const [showSocialUploads, setShowSocialUploads] = useState(false);
  const [fileError, setFileError] = useState(null);

  const handleUpload = ({ file, filename, avatarTypeId }) => {
    // Read file as base64 and put it inside the store
    if (file) {
      readFileAsBase64(file).then((avatarBase64) => {
        saveAvatar({
          id: avatarTypeId,
          thumbnail: avatarBase64,
          file,
          filename,
          dispatch,
        });
        setSelectedAvatar(avatarTypeId);
        setFileError(null);
      });
    }
  };

  const handleError = (error) => {
    Sentry.captureException(error);
    setFileError(error.message);
  };

  const handleSubmit = () => {
    updateAvatar({ avatarTypeId: selectedAvatar });
    closePopupWithTransition();
  };

  return (
    <div className={`${isForm ? "popup-avatar--form" : ""} popup-avatar`}>
      <button
        className="popup-cv__close"
        onClick={() => closePopupWithTransition(false)}
        type="button"
      >
        ✕
      </button>
      <div className="popup__content">
        <h3 className="popup__title">{i18n.t("photoPopup.title")}</h3>
        <form
          className={`popup__tab ${!showFacebookPhotos && "active"}`}
          id="popup-form"
        >
          <div className="popup__variants">
            {Object.keys(avatars).map((avatarKey) => {
              const data = avatars[avatarKey];
              if (data.file) {
                return (
                  <AvatarVariant
                    avatar={data.thumbnail}
                    selectedAvatar={selectedAvatar}
                    setSelectedAvatar={setSelectedAvatar}
                    avatarTypeId={avatarKey}
                    network={data.name}
                  />
                );
              }
              return null;
            })}
          </div>
          <div className={`popup__upload ${showSocialUploads ? "show" : ""}`}>
            <button
              className="popup__upload-socials"
              onClick={(event) => {
                event.preventDefault();
                setShowSocialUploads(true);
              }}
            >
              <p className="popup__upload-text">
                {i18n.t("photoPopup.uploadFromMinifiedTitle")}
              </p>
              <div className="popup__upload-list">
                {!linkedInUrl && (
                  <img
                    src={linkedInIconSvg}
                    alt=""
                    className="popup__upload-icon"
                  />
                )}
                {!facebookUserId && (
                  <img
                    src={facebookIconSvg}
                    alt=""
                    className="popup__upload-icon"
                  />
                )}
                {!isFacebookInAppBrowser() && (
                  <Fragment>
                    <img
                      src={dropboxIconSvg}
                      alt=""
                      className="popup__upload-icon"
                    />
                    <img
                      src={googleDriveIconSvg}
                      alt=""
                      className="popup__upload-icon"
                    />
                  </Fragment>
                )}
              </div>
            </button>
            {!linkedInUrl && (
              <LinkedInAuth
                redirectUrl={window.location.href}
                render={({ onClick }) => (
                  <div
                    onClick={onClick}
                    role="presentation"
                    className="popup__upload-item social social-li"
                  >
                    <div className="popup__upload-icon">
                      <img src={linkedInIconSvg} alt="" />
                    </div>
                    <p className="popup__upload-text">
                      {i18n.t("photoPopup.uploadFromNetwork", {
                        network: "LinkedIn",
                      })}
                    </p>
                  </div>
                )}
              />
            )}
            <UploadDropbox
              avatarTypeId="dropbox"
              handleUpload={handleUpload}
              handleError={handleError}
              fieldName="avatar"
            />
            <UploadGoogleDrive
              avatarTypeId="googleDrive"
              handleUpload={handleUpload}
              handleError={handleError}
              fieldName="avatar"
            />
            <UploadStorage
              avatarTypeId="storage"
              handleUpload={handleUpload}
              handleError={handleError}
              fieldName="avatar"
            />
          </div>
          {fileError && <p className="avatar-popup__error">{fileError}</p>}
          <label className="popup__no-photo" htmlFor="unselect-avatar">
            <input
              className="popup__radio"
              type="radio"
              id="unselect-avatar"
              checked={selectedAvatar === null}
              onChange={() => setSelectedAvatar(null)}
            />
            <p
              className={`popup__profile-text ${!selectedAvatar &&
                "popup__profile-text--selected"}`}
            >
              {i18n.t("photoPopup.dontUseTitle")}
            </p>
            <span className="popup__checked-status" />
          </label>
        </form>
        <div className="popup__controlsContainer">
          <button
            className="popup__cancel"
            onClick={() => closePopupWithTransition()}
          >
            {i18n.t("photoPopup.buttons.cancel")}
          </button>
          <button className="popup__confirm" onClick={handleSubmit}>
            {i18n.t("photoPopup.buttons.confirm")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AvatarPopup;
