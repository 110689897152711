import { h } from "preact";
import { useContext } from "preact/hooks";
import i18n from "i18next";
import { Store } from "../../store";

const ProfileContactDetails = () => {
  const { state } = useContext(Store);

  const { jobPost } = state.form;
  const contact = jobPost.contacts[0];

  if (!contact) return null;

  return (
    <div className="profile__contact">
      <h2>{i18n.t("confirmation.contactHr")}</h2>
      <div className="profile__contact--details">
        {contact.avatar && <img src={contact.avatar} alt="" />}
        <div className="links">
          <span>{contact.email}</span>
          {contact.phone && (
            <span className="profile__contact--details__phone">
              {contact.phone}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileContactDetails;
