import { readFileAsBase64 } from "../utils";

const saveCv = ({ file, filename, dispatch }) => {
  dispatch({
    type: "UPDATE_CV",
    payload: { file, filename }
  });
  readFileAsBase64(file).then(fileBase64 => {
    sessionStorage.setItem("cvFile", fileBase64);
    sessionStorage.setItem("cvFilename", filename);
  });
};

export default saveCv;
