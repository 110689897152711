const joinCitySlugs = (locations) => {
  const joinedSlug = locations
    ? locations
        .map((location) => location.citySlug)
        .filter(Boolean)
        .join("-") || "-"
    : "-";

  return joinedSlug;
};

export default joinCitySlugs;
