import { h } from "preact";
import { useState, useEffect } from "preact/hooks";
import { joinCitySlugs } from "../../utils";
import "./SecretIndexPage.scss";

// TODO: Fetch the data and show it
const SecretIndexPage = () => {
  const [jobPosts, setJobPosts] = useState([]);
  const [customersById, setCustomers] = useState({});

  console.log({ customersById, jobPosts });
  useEffect(() => {
    fetch("/api/jobPost/jobPosts")
      .then((res) => res.json())
      .then((data) => {
        setJobPosts(data.jobPosts);
        setCustomers(
          Object.fromEntries(
            data.customers.map((customer) => [customer._id, customer])
          )
        );
      });
  }, []);
  return (
    <div className="secret-index-page">
      {jobPosts.map((jobPost) => (
        <div className="index-page-item">
          <a
            href={`/${customersById[jobPost.customerId].slug}/${
              jobPost.atsJobPostId
            }/${joinCitySlugs(jobPost.locations)}/${jobPost.titleSlug}`}
            native
          >
            {jobPost.title}
          </a>
          <span>{jobPost.locations[0]?.city}</span>
          <span>{customersById[jobPost.customerId].name}</span>
        </div>
      ))}
    </div>
  );
};

export default SecretIndexPage;
