import { h } from "preact";
import "./ProgressBar.scss";

const ProgressBar = ({ step }) => {
  return (
    <div className={`progress-bar progress-bar--step-${step}`}>
      <ul>
        <li className="completed" />
        <li className="active" />
        <li />
      </ul>
    </div>
  );
};

export default ProgressBar;
