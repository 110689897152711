import { useEffect } from "preact/hooks";
import { route } from "preact-router";
import queryString from "query-string";

export const LinkedInRedirectPage = () => {
  useEffect(() => {
    const params = queryString.parse(window.location.search);
    const redirectUrl = sessionStorage.getItem("linkedInRedirectUrl");

    if (params.code) {
      window.linkedInCode = params.code;
    }
    route(redirectUrl, true);
  }, []);
  return null;
};

export default LinkedInRedirectPage;
