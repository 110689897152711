import { h } from "preact";
import { useContext, useEffect } from "preact/hooks";
import { route } from "preact-router";
import i18n from "i18next";
import { Store } from "../../store";
import { getPagePath } from "../../selectors";
import { trackRegistration } from "../../tracking";
import ConfirmationTitleBlock from "../../components/ConfirmationTitleBlock/ConfirmationTitleBlock";
import ProfileStrengthImprovement from "../../components/ProfileStrengthImprovement/ProfileStrengthImprovement";
import ProfileSummary from "../../components/ProfileSummary/ProfileSummary";
import ProfileContactDetails from "../../components/ProfileContactDetails/ProfileContactDetails";
import "./ConfirmationPage.scss";

const ConfirmationPage = () => {
  const { state } = useContext(Store);
  const { isSubmitted } = state.application;

  useEffect(() => {
    if (!isSubmitted) {
      route(`${getPagePath(state)}/apply`);
    }
  }, []);
  const { cv } = state.application;
  const { confirmedAvatar } = state.avatar;
  const { tracking } = state;

  const hasCv = !!(cv && cv.file);
  const hasAvatar = !!confirmedAvatar;
  // Add booleans
  const numberOfProfileStrengths = hasCv + hasAvatar;
  const profileStrengthPercentage = 80 + numberOfProfileStrengths * 10;
  useEffect(() => {
    if (tracking.isInitialized) {
      trackRegistration(state.form.customer);
    }
  }, [tracking.isInitialized]);
  return (
    <div className="applied-screen">
      <div className="container">
        <div className="job-screen__title applied-screen__title">
          <span>{i18n.t("confirmation.title")}</span>
          <div className="confirmation-animated-header">
            <ConfirmationTitleBlock />
          </div>
        </div>

        <div className="profile">
          <ProfileSummary
            profileStrengthPercentage={profileStrengthPercentage}
          />
          <ProfileStrengthImprovement hasAvatar={hasAvatar} hasCv={hasCv} />
          <ProfileContactDetails />
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPage;
