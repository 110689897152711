// import "preact/debug";
import "whatwg-fetch";
import "core-js/stable/promise";
import "core-js/stable/array/find-index";
import { h, render } from "preact";
import { HeadProvider } from "react-head";
import * as Sentry from "@sentry/browser";
import App from "./app/App";

const preloadedState = window.PRELOADED_STATE;
delete window.PRELOADED_STATE;

Sentry.init({
  dsn: process.env.SENTRY_FRONTEND_DSN,
  environment: process.env.NODE_ENV,
});

render(
  <HeadProvider>
    <App preloadedState={preloadedState} />
  </HeadProvider>,
  document.body
);
