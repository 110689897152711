import { h } from "preact";
import i18n from "i18next";
import { useContext } from "preact/hooks";
import { Store } from "../../store";
import LinkedInAuth from "../LinkedInAuth/LinkedInAuth";
import { isFacebookInAppBrowser } from "../../utils";
import { getPagePath } from "../../selectors";
import linkedInSvg from "../../../assets/images/linkedIn.svg";
import "./ConnectWith.scss";

const ConnectWith = () => {
  const { state, dispatch } = useContext(Store);
  if (isFacebookInAppBrowser()) return null;
  const { linkedInUrl } = state.application;
  const applyUrl = `${getPagePath(state)}/apply`;
  return (
    <div className="form-screen__block connect-with">
      <h2>{i18n.t("applyForm.connectWith")}</h2>
      <LinkedInAuth
        redirectUrl={applyUrl}
        render={({ onClick }) => (
          <label
            className={`${linkedInUrl ? "checked" : null}`}
            onClick={linkedInUrl ? () => null : onClick}
            htmlFor="connect-with-linkedIn"
          >
            <img src={linkedInSvg} alt="" />
            <input
              className="radio linkedin"
              type="checkbox"
              name="connect-with-linkedIn"
              value="linkedin"
              checked={!!linkedInUrl}
            />
            <span className="radiomark" />
          </label>
        )}
      />
    </div>
  );
};

export default ConnectWith;
