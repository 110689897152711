import { h } from "preact";
import { useContext } from "preact/hooks";
import { Store } from "../../store";
import { validateField, getFormFieldClass } from "../../utils";
import { updateApplicationInput } from "../../actionCreators";

const CheckboxField = ({ field }) => {
  const { state, dispatch } = useContext(Store);
  const { answers, profileInfo } = state.application;
  const fieldData = answers[field.atsQuestionId] || profileInfo[field.name];

  const checkedInputs = fieldData.value;

  const handleInput = event => {
    const { name, checked } = event.target;
    const error = validateField({
      value: checkedInputs,
      validation: field.validation
    });
    const newCheckedInputs = checked
      ? [...checkedInputs, name]
      : checkedInputs.filter(item => item !== name);
    updateApplicationInput({
      name: field.name,
      atsQuestionId: field.atsQuestionId,
      value: newCheckedInputs,
      error,
      dispatch
    });
  };

  const formFieldClass = getFormFieldClass(fieldData);

  return (
    <div className={`form-field ${formFieldClass}`}>
      <span className="form-field__label">{field.label}</span>
      {field.options.map(option => (
        <div>
          <label htmlFor={option.id} className="form-field__checkbox-group">
            <input
              id={option.id}
              onClick={handleInput}
              name={option.name}
              type={field.type}
              checked={checkedInputs.includes(option.name)}
            />
            <span className="form-field__checkbox-group__status" />
            <span className="form-field__checkbox-group__text">
              {option.name}
            </span>
          </label>
        </div>
      ))}
      {fieldData.showValidation && (
        <p className="form-field__error">{fieldData.error}</p>
      )}
    </div>
  );
};

export default CheckboxField;
