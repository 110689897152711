import { h, Fragment } from "preact";
import { createRef } from "preact/compat";
import { useEffect, useContext, useState } from "preact/hooks";
import { Router } from "preact-router";
import { Store } from "./store";
import { fetchLinkedInProfile, fetchForm } from "./actionCreators";
import { initializeTracking } from "./tracking";
import JobOverviewPage from "./pages/JobOverviewPage/JobOverviewPage";
import ProfileFormPage from "./pages/ProfileFormPage/ProfileFormPage";
import QuestionFormPage from "./pages/QuestionFormPage/QuestionFormPage";
import ConfirmationPage from "./pages/ConfirmationPage/ConfirmationPage";
import Head from "./components/Head";
import CookieConsentBanner from "./components/CookieConsentBanner/CookieConsentBanner";

const Main = ({ customerSlug, atsJobPostId, url }) => {
  const { dispatch, state } = useContext(Store);

  const [cookieConsentBannerHeight, setCookieConsentBannerHeight] = useState(
    false
  );

  const cookieConsentBanner = createRef();

  useEffect(() => {
    if (!state.form) {
      fetchForm({ customerSlug, atsJobPostId, dispatch });
    }
  }, []);

  const cookieConsentStatus = state.tracking.cookieConsentStatus;

  const hasForm = !!state.form;

  useEffect(() => {
    if (hasForm) {
      const { profileFields } = state.form.jobPost.applyForm;
      fetchLinkedInProfile({ profileFields, dispatch });

      if (cookieConsentStatus === "consented") {
        const { jobPost, customer } = state.form;
        initializeTracking({ jobPost, customer, dispatch });
      }
    }
  }, [hasForm, cookieConsentStatus]);

  useEffect(() => {
    if (cookieConsentBanner && cookieConsentBanner.current) {
      let bannerHeight = cookieConsentBanner.current.offsetHeight;
      if (bannerHeight && bannerHeight > 0) {
        bannerHeight += 24;
      }
      setCookieConsentBannerHeight(bannerHeight);
    } else {
      setCookieConsentBannerHeight(null);
    }
  });

  if (!state.form) {
    return null;
  }
  return (
    <Fragment>
      <Head />
      <div
        className={`overflow-wrapper ${state.form.customer.slug}`}
        style={{ "margin-bottom": cookieConsentBannerHeight }}
      >
        <Router url={url}>
          <JobOverviewPage path="/:customerSlug/:atsJobPostId/:citySlug?/:titleSlug?/" />
          <ProfileFormPage path="/:customerSlug/:atsJobPostId/:citySlug/:titleSlug/apply" />
          {/* Duplicate profile form at easy URL to enable redirection from ATS's */}
          <ProfileFormPage path="/:customerSlug/:atsJobPostId/apply" />
          <QuestionFormPage path="/:customerSlug/:atsJobPostId/:citySlug/:titleSlug/questions" />
          <ConfirmationPage path="/:customerSlug/:atsJobPostId/:citySlug/:titleSlug/thanks" />
        </Router>
      </div>
      {state.tracking.cookieConsentStatus === "undecided" && (
        <CookieConsentBanner ref={cookieConsentBanner} />
      )}
    </Fragment>
  );
};

export default Main;
