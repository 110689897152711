import i18n from "i18next";
import { h } from "preact";
import { useContext } from "preact/hooks";
import buildingSvg from "../../../assets/images/buildingWhite.svg";
import clockSvg from "../../../assets/images/clockWhite.svg";
import pinSvg from "../../../assets/images/pinWhite.svg";
import { Store } from "../../store";
import "./ConfirmationTitleBlock.scss";

const ConfirmationTitleBlock = () => {
  const { state } = useContext(Store);

  const { jobPost } = state.form;
  const { title, locations, isPartTime } = jobPost;

  return (
    <div className="confirmation-title-block">
      <h1>{title}</h1>
      <div className="job-screen__job-info">
        {locations && locations.filter((location) => location.city).length > 0 && (
          <div className="location">
            <img src={pinSvg} alt="" />
            {locations.map((location) => location.city).join(" / ")}
          </div>
        )}
        <div>
          <img src={clockSvg} alt="" />
          {isPartTime
            ? i18n.t("details.notFullTime")
            : i18n.t("details.fullTime")}
        </div>
      </div>
    </div>
  );
};

export default ConfirmationTitleBlock;
