import validateField from "./validateField";
import readBase64UrlAsBlob from "./readBase64UrlAsBlob";
import { getCountryCodeFromCountry } from "./phoneNumberHelpers";

// Create initial form input state based on form data
const getInitialApplicationData = (formData) => {
  const defaultApplication = {
    cv: {},
    coverLetter: {},
    isSubmitting: false,
    isSubmitted: false,
    error: null,
    termsAccepted: true,
  };
  if (!formData) {
    return defaultApplication;
  }

  const { jobPost, customer } = formData;

  if (customer.slug === "peab") {
    defaultApplication.termsAccepted = false;
  }

  const { profileFields, questionFields, useRedirectMode } = jobPost.applyForm;
  if (useRedirectMode) {
    return defaultApplication;
  }

  const profileInfo = Object.fromEntries(
    profileFields.map((field) => {
      const storedValue = getSessionItem(field.name);
      const parsedStoredValue = getStoredValue(storedValue, field);
      const defaultValue = getInitialFieldValue(field, jobPost);
      const value = parsedStoredValue || defaultValue;
      return [
        field.name,
        {
          value,
          showValidation: !!parsedStoredValue,
          error: validateField({ value, validation: field.validation }),
        },
      ];
    })
  );

  const storedCountryCode = getSessionItem("countryCode");
  const countryCodeValue =
    storedCountryCode ||
    (profileInfo.country?.value
      ? getCountryCodeFromCountry(profileInfo.country?.value)
      : "");

  profileInfo.countryCode = {
    value: countryCodeValue,
    showValidation: !!countryCodeValue,
    error: validateField({
      value: countryCodeValue,
      validation: { required: true },
    }),
  };

  const displayedPhoneNumber = getSessionItem("displayedPhoneNumber") || "";
  profileInfo.displayedPhoneNumber = {
    value: displayedPhoneNumber,
    showValidation: !!displayedPhoneNumber,
    error: validateField({
      value: displayedPhoneNumber,
      validation: { required: true, pattern: "\\d{6,15}" },
    }),
  };

  const answers = Object.fromEntries(
    questionFields.map((field) => {
      const storedValue = getSessionItem(field.atsQuestionId);
      const parsedStoredValue = getStoredValue(storedValue, field);
      const defaultValue = getInitialFieldValue(field, jobPost);
      const value = parsedStoredValue || defaultValue;
      return [
        field.atsQuestionId,
        {
          value,
          showValidation: !!parsedStoredValue,
          error: validateField({ value, validation: field.validation }),
        },
      ];
    })
  );
  const cvBase64 = getSessionItem("cvFile");
  const cvBlob = cvBase64 && readBase64UrlAsBlob(cvBase64);
  const cvFilename = getSessionItem("cvFilename");
  const coverLetterBase64 = getSessionItem("coverLetterFile");
  const coverLetterBlob =
    coverLetterBase64 && readBase64UrlAsBlob(coverLetterBase64);
  const coverLetterFilename = getSessionItem("coverLetterFilename");
  const applicationIdRaw = getSessionItem(`applicationId-${jobPost._id}`);
  const applicationId = applicationIdRaw === "123" ? null : applicationIdRaw;
  const isSubmitted = !!applicationId;
  const linkedInUrl = getSessionItem("linkedInUrl");
  const facebookUserId = getSessionItem("facebookUserId");
  return {
    ...defaultApplication,
    isSubmitted,
    id: applicationId,
    profileInfo,
    answers,
    cv: { file: cvBlob, filename: cvFilename },
    coverLetter: {
      file: coverLetterBlob,
      filename: coverLetterFilename,
    },
    linkedInUrl,
    facebookUserId,
  };
};

const getSessionItem = (key) =>
  typeof window !== "undefined" && (sessionStorage.getItem(key) ?? undefined);

const getStoredValue = (storedValue, field) => {
  if (field.type === "number") return Number(storedValue);
  if (field.type === "checkbox") return JSON.stringify(storedValue);
  return storedValue;
};

const getInitialFieldValue = (field, jobPost) => {
  if (field.name === "country") return jobPost.locations?.[0]?.country || "";
  if (field.type === "checkbox") return [];
  if (field.type === "number") return null;
  return "";
};

export default getInitialApplicationData;
