import { h } from "preact";
import { useContext } from "preact/hooks";
import { forwardRef } from "preact/compat";
import { Store } from "../../store";
import "./CookieConsentBanner.scss";
import { initializeTracking } from "../../tracking";

const CookieConsentBanner = forwardRef((props, ref) => {
  const { state, dispatch } = useContext(Store);
  const { jobPost, customer } = state.form;

  const cookieConsentLink = customer.cookieConsentLink ?? "/cookie-policy";

  const handleCookiesConsented = () => {
    initializeTracking({ jobPost, customer, dispatch });
    dispatch({
      type: "SET_COOKIE_CONSENT_STATUS",
      payload: "consented",
    });
    localStorage.setItem("cookieConsentStatus", "consented");
  };

  const handleCookiesRejected = () => {
    dispatch({
      type: "SET_COOKIE_CONSENT_STATUS",
      payload: "rejected",
    });
    localStorage.setItem("cookieConsentStatus", "rejected");
  };

  return (
    <div className="cookie-consent-banner" ref={ref}>
      <div className="cookie-consent-banner__heading">Cookies and Privacy</div>

      <div
        className="cookie-consent-banner__text"
        dangerouslySetInnerHTML={{
          __html:
            customer.cookieConsentText ??
            `<p>We use cookies 🍪 to ensure that we give you the best experience on our website, <a class='cookie-consent-banner__terms-link' target='_BLANK' href='${cookieConsentLink}'>read our privacy policy</a><p>`,
        }}
      />
      <div className="cookie-consent-banner__button-group">
        <button className="reject-button" onClick={handleCookiesRejected}>
          Decline
        </button>
        <button className="consent-button" onClick={handleCookiesConsented}>
          Accept
        </button>
      </div>
    </div>
  );
});

export default CookieConsentBanner;
