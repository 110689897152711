import { Component } from "preact";
import * as Sentry from "@sentry/browser";
export default class ErrorBoundary extends Component {
  componentDidCatch(error) {
    Sentry.captureException(error);
    console.error(error);
  }

  render() {
    const { children } = this.props;
    return children;
  }
}
