export const checkIfProfileFieldsAreValid = (state) => {
  const { application, form } = state;
  const { profileFields } = form.jobPost.applyForm;

  return (
    getFieldsToValidate(profileFields).every(
      ({ name }) => !application.profileInfo[name].error
    ) && checkIfCvIsValid(state)
  );
};
const checkIfCvIsValid = (state) => {
  const { application, form } = state;
  const { required } = form.jobPost.applyForm.cv;
  return !required || !!application.cv.file;
};

export const checkIfAllFieldsAreValid = (state) => {
  const { application, form } = state;
  const { profileFields, questionFields } = form.jobPost.applyForm;
  return (
    getFieldsToValidate(profileFields).every(
      ({ name }) => !application.profileInfo[name].error
    ) &&
    questionFields.every(
      ({ atsQuestionId }) => !application.answers[atsQuestionId].error
    ) &&
    checkIfCvIsValid(state)
  );
};

// Phonenumber is constructed from countryCode and displayedPhoneNumber and can therefore be bypassed when validating. Add these fields to the validation instead
const getFieldsToValidate = (profileFields) => {
  const virtualFields = [
    { name: "countryCode" },
    { name: "displayedPhoneNumber" },
  ];
  return [...profileFields, ...virtualFields].filter(
    (field) => field.name !== "phone"
  );
};
