// Set data on google analytics object so it's included in ga events
// Custom dimensions need to be sent with these keys
const dimensionMap = {
  company: "dimension1",
  jobLocation: "dimension2",
  jobCategory: "dimension3",
  atsJobPostId: "dimension4",
};

const gaTrackingId = process.env.GOOGLE_ANALYTICS_TRACKING_ID;

const initializeGoogleAnalytics = ({ customer, jobPost }) => {
  return new Promise((resolve, _reject) => {
    const { atsJobPostId, categories, locations } = jobPost;

    /* eslint-disable */
    function configureGa() {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function () {
        window.dataLayer.push(arguments);
      };
      window.gtag("js", new Date());

      window.gtag("config", gaTrackingId);

      // TODO
      // if (customer.trackingMode === "anonymous") {
      //   window.ga("set", "anonymizeIp", true);
      // }

      window.gtag("set", {
        [dimensionMap.company]: customer.slug,
        [dimensionMap.jobLocation]: locations.find(({ city }) => city)?.city,
        [dimensionMap.jobCategory]: categories[0],
        [dimensionMap.atsJobPostId]: atsJobPostId,
      });
      resolve();
    }

    const gaScript = document.createElement("script");
    gaScript.addEventListener("load", configureGa);

    gaScript.type = "text/javascript";
    gaScript.src =
      "https://www.googletagmanager.com/gtag/js?id=" + gaTrackingId;
    document.body.appendChild(gaScript);
    /* eslint-enable */
  });
};

export default initializeGoogleAnalytics;
