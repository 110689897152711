import { h, Fragment } from "preact";
import { createPortal } from "preact/compat";
import { useState, useContext } from "preact/hooks";
import i18n from "i18next";
import { Store } from "../../store";
import Popup from "../Popup/Popup";
import CvPopup from "../CvPopup/CvPopup";
import { isFacebookInAppBrowser } from "../../utils";
import { saveCv } from "../../actionCreators";
import dropboxIconSvg from "../../../assets/images/dropboxIcon.svg";
import googleDriveIconSvg from "../../../assets/images/googleDriveIcon.svg";
import uploadIconSvg from "../../../assets/images/uploadIcon.svg";

const CvField = () => {
  const { state, dispatch } = useContext(Store);
  const [popupOpen, setPopupOpen] = useState(false);
  const { cv } = state.application;
  const { required } = state.form.jobPost.applyForm.cv;

  const handleClick = (event) => {
    event.preventDefault();
    setPopupOpen(true);
  };

  const updateCv = ({ file, filename }) => {
    saveCv({ file, filename, dispatch });
  };

  return (
    <div className="form-field cv-field">
      {popupOpen &&
        createPortal(
          <Popup setPopupOpen={setPopupOpen}>
            <CvPopup updateCv={updateCv} />
          </Popup>,
          // Portal into this div to let the modal get the right position and width
          document.querySelector(".form-screen__form-section")
        )}
      <div className="form-field__label-group">
        <label className="form-field__label" htmlFor="upload-cv-button">
          {i18n.t("label.cv")}
        </label>
        <span>
          {required ? i18n.t("form.required") : i18n.t("form.optional")}
        </span>
      </div>
      <button
        id="upload-cv-button"
        className="cv-field__button"
        onClick={handleClick}
      >
        {cv.file ? (
          <Fragment>
            <span className="cv-field__file-name">{cv.filename}</span>
            <span className="checkbox-icon" />
          </Fragment>
        ) : (
          <Fragment>
            <div>
              {i18n.t(
                isFacebookInAppBrowser()
                  ? "cvPopup.title"
                  : "form.cvFieldUpload"
              )}
            </div>
            <span className="cv-field__social-icons">
              {isFacebookInAppBrowser() || (
                <Fragment>
                  <img src={dropboxIconSvg} alt="" />
                  <img src={googleDriveIconSvg} alt="" />
                </Fragment>
              )}
              <img src={uploadIconSvg} alt="" />
            </span>
          </Fragment>
        )}
      </button>
      {cv.file && (
        <span className="cv-field__hint">{i18n.t("form.cvFieldHint")}</span>
      )}
    </div>
  );
};

export default CvField;
