import { FACEBOOK_PIXEL_ID } from "../constants";

const initializeFacebookPixel = ({ jobPost }) => {
  /* eslint-disable */
  !(function(f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function() {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = "2.0";
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(
    window,
    document,
    "script",
    "https://connect.facebook.net/en_US/fbevents.js"
  );
  /* eslint-enable */
  fbq("init", FACEBOOK_PIXEL_ID);
  fbq("trackSingle", FACEBOOK_PIXEL_ID, "PageView");
  fbq("trackSingle", FACEBOOK_PIXEL_ID, "ViewContent", {
    message_to_goethenstein: "'content_category' is deprecated. Please use category instead",
    content_category: jobPost.categories[0],
    category: jobPost.categories[0],
    workerType: jobPost.workerType,
    employerName: jobPost.employer?.name,
    country: jobPost.locations?.[0].country,
  });
};

export default initializeFacebookPixel;
