import { h } from "preact";
import { useContext, useEffect } from "preact/hooks";
import { route } from "preact-router";
import i18n from "i18next";
import { Store } from "../../store";
import { submitApplication } from "../../actionCreators";
import FormField from "../FormFields/FormField";
import Spinner from "../Spinner/Spinner";
import {
  getPagePath,
  checkIfProfileFieldsAreValid,
  checkIfAllFieldsAreValid
} from "../../selectors";

const QuestionFormBlock = () => {
  const { state, dispatch } = useContext(Store);
  const { application, form } = state;
  const { questionFields } = form.jobPost.applyForm;
  const { isSubmitting, isSubmitted, error } = application;

  useEffect(() => {
    const areProfileFieldsValid = checkIfProfileFieldsAreValid(state);
    if (!areProfileFieldsValid) {
      route(`${getPagePath(state)}/apply`);
    }
  }, []);

  const areAllFieldsValid = checkIfAllFieldsAreValid(state);
  const handleSubmit = event => {
    event.preventDefault();

    if (areAllFieldsValid) {
      submitApplication(state, dispatch);
    } else {
      questionFields.forEach(({ atsQuestionId }) => {
        dispatch({
          type: "SHOW_VALIDATION",
          payload: { atsQuestionId }
        });
      });
    }
  };

  const isButtonDisabled = isSubmitted || isSubmitting || !areAllFieldsValid;
  return (
    <div className="form-screen__block">
      <form onSubmit={handleSubmit} noValidate>
        {questionFields.map(field => (
          <FormField field={field} />
        ))}
        <div className="form-screen__button-wrapper">
          <button
            disabled={isButtonDisabled}
            type="submit"
            className={`form-screen__button ${isButtonDisabled &&
              "form-screen__button--disabled"}`}
          >
            {isSubmitting ? <Spinner /> : i18n.t("form.buttons.submit")}
          </button>
        </div>
        {isSubmitted && (
          <div className="form-screen__error">
            <p className="form-screen__error-text">
              {i18n.t("form.alreadySubmitted")}
            </p>
          </div>
        )}
        {error && (
          <div className="form-screen__error">
            <p className="form-screen__error-text">
              {i18n.t("form.submitFailed", { error })}
            </p>
          </div>
        )}
      </form>
    </div>
  );
};

export default QuestionFormBlock;
