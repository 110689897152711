import readBase64UrlAsBlob from "./readBase64UrlAsBlob";

const getInitialAvatar = () => {
  const defaultAvatar = {
    confirmedAvatar: null,
    avatars: {
      facebook: {
        name: "Facebook",
        file: null
      },
      linkedIn: {
        name: "LinkedIn",
        file: null
      },
      dropbox: {
        name: "Dropbox",
        file: null
      },
      googleDrive: {
        name: "Google Drive",
        file: null
      },
      storage: {
        file: null,
        // Hardcoded for storage as it gets it's own string that can be localized.
        name: "storage"
      }
    }
  };

  if (typeof sessionStorage === "undefined") return defaultAvatar;
  const id = sessionStorage.getItem("avatarId");
  if (!id) return defaultAvatar;
  const avatarBase64 = sessionStorage.getItem(`avatar-${id}`);
  const avatarBlob = avatarBase64 && readBase64UrlAsBlob(avatarBase64);
  const avatarFilename = sessionStorage.getItem(`avatarFilename-${id}`);
  return {
    ...defaultAvatar,
    confirmedAvatar: id,
    avatars: {
      ...defaultAvatar.avatars,
      [id]: {
        ...defaultAvatar.avatars[id],
        file: avatarBlob,
        filename: avatarFilename,
        thumbnail: avatarBase64
      }
    }
  };
};

export default getInitialAvatar;
