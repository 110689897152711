import { h } from "preact";
import { route } from "preact-router";
import {
  useRef,
  useState,
  useContext,
  useEffect,
  useLayoutEffect,
} from "preact/hooks";
import i18n from "i18next";
import { getPagePath } from "../../selectors";
import EmailRedirectForm from "../../components/EmailRedirectForm/EmailRedirectForm";
import { Store } from "../../store";
import TitleBlock from "../../components/TitleBlock/TitleBlock";
import pinSvg from "../../../assets/images/pin.svg";
import clockSvg from "../../../assets/images/clock.svg";

import "./JobOverviewPage.scss";
import RedirectScreen from "../../components/RedirectScreen/RedirectScreen";

const DESCRIPTION_CUTOFF_HEIGHT = 144;

const JobOverviewPage = (props) => {
  const { state } = useContext(Store);
  const [isDescriptionExpanded, setDescriptionExpansion] = useState(false);
  const [useDesktopOverflow, setUseDesktopOverflow] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);
  // We need a separate hidden description paragraph from which we get the height for the real
  // description paragraph. We can't just use height:100% since it doesn't work with the animation
  const hiddenDescription = useRef();
  const benefitsRef = useRef();
  const { customer, jobPost } = state.form;
  const {
    descriptionHeader,
    descriptionFooter,
    description,
    benefits,
    applyForm,
    isActive,
  } = jobPost;

  const presentUtmTags = Object.entries(props?.matches ?? {}).filter(([key]) =>
    key.startsWith("utm")
  );

  const getLinkWithExistingUtmTags = (targetLink) => {
    const targetUrl = new URL(targetLink);

    const { origin, searchParams, pathname } = targetUrl;

    presentUtmTags.forEach(([utmTag, value]) => {
      searchParams.set(utmTag, value);
    });

    const newUrl = new URL(`${origin}${pathname}?${searchParams.toString()}`);

    return newUrl;
  };

  const shouldUseDesktopOverflow = () => {
    const descriptionHeight = isDescriptionExpanded
      ? hiddenDescription.current?.offsetHeight
      : DESCRIPTION_CUTOFF_HEIGHT;

    const descriptionOffset =
      hiddenDescription.current?.getBoundingClientRect().y || 0;

    const benefitsHeight = benefitsRef.current?.offsetHeight || 0;
    const bottomMargin = 90;

    return (
      descriptionOffset + descriptionHeight + benefitsHeight + bottomMargin >=
      window.innerHeight
    );
  };

  useLayoutEffect(() => {
    const onWindowResize = () => {
      setUseDesktopOverflow(shouldUseDesktopOverflow());
    };

    window.addEventListener("resize", onWindowResize);
    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  useEffect(() => {
    setUseDesktopOverflow(shouldUseDesktopOverflow());
  }, [isDescriptionExpanded]);

  const jobDescription = [descriptionHeader, description, descriptionFooter]
    .filter((text) => !!text)
    .join("\n");

  const applyUrl = `${getPagePath(state)}/apply`;
  const cookiesBannerPresent =
    state?.tracking?.cookieConsentStatus === "undecided";

  const toggleDescriptionExpansion = () => {
    setDescriptionExpansion(!isDescriptionExpanded);
  };

  const isDescriptionCutOff = hiddenDescription.current
    ? DESCRIPTION_CUTOFF_HEIGHT < hiddenDescription.current?.offsetHeight
    : true;

  const { title, locations, isPartTime } = jobPost;

  const getCallToAction = () => {
    let callToAction = null;
    if (isActive) {
      if (!applyForm.useRedirectMode) {
        callToAction = (
          <div
            className={`job-description__apply-buttons ${
              !cookiesBannerPresent
                ? "job-description__apply-buttons-fixed"
                : ""
            } ${useDesktopOverflow ? "desktop-overflow" : ""}`}
          >
            <div className="apply-buttons-wrapper">
              <button
                onClick={() => route(applyUrl)}
                className="social-apply-button manual"
                style={{ background: customer.customerButtonColor || null }}
              >
                {i18n.t("description.applyNow")}
              </button>
            </div>
          </div>
        );
      } else {
        callToAction = (
          <div
            className={`job-description__apply-buttons ${
              !cookiesBannerPresent
                ? "job-description__apply-buttons-fixed"
                : ""
            }  ${useDesktopOverflow ? "desktop-overflow" : ""}`}
          >
            <div className="apply-buttons-wrapper">
              <button
                className="social-apply-button manual"
                style={{ background: customer.customerButtonColor || null }}
                onClick={() => {
                  const href = getLinkWithExistingUtmTags(
                    jobPost.applicationUrl || jobPost.url
                  )?.href;

                  setRedirectUrl(href);
                }}
              >
                {i18n.t("description.applyNow")}
              </button>
            </div>
          </div>
        );
        if (applyForm.useEmailRedirectForm) {
          callToAction = <EmailRedirectForm jobPost={jobPost} />;
        }
      }
    }
    return callToAction;
  };

  const getJobLocationList = () => {
    const MULTIPLE_LOCATIONS_JOBPOST_ATS_IDS = [
      "15312",
      "15309",
      "15310",
      "15832",
      "16613",
      "16614",
      "16618",
      "16619",
      "16620"
    ];

    if (MULTIPLE_LOCATIONS_JOBPOST_ATS_IDS.includes(jobPost.atsJobPostId)) {
      return [{
        city: i18n.t("details.multipleLocations")
      }]
    }

    return locations;
  }


  return (
    <div className="job-description">
      <TitleBlock />
      <div className="job-description__container">
        <div className="job-description__content">
          <h2 className="job-description__title">{title}</h2>
          <ul className="job-screen__job-info">
            {getJobLocationList(jobPost).map((location) => (
              <li>
                <img src={pinSvg} alt="" />
                {location.city}
              </li>
            ))}

            {!["circlek"].includes(customer.slug) && <li>
              <img src={clockSvg} alt="" />
              {isPartTime
                ? i18n.t("details.notFullTime")
                : i18n.t("details.fullTime")}
            </li>}

          </ul>
          <div className="call-to-action__mobile">{getCallToAction()}</div>
          {!isActive && (
            <div className="job-expired-notice">
              <p className="job-expired-notice-text">
                {i18n.t("description.jobExpired")}
              </p>
            </div>
          )}
          <div className="job-description__main-content">
            <div
              dangerouslySetInnerHTML={{ __html: jobDescription }}
              className="job-description__text"
              style={{
                height: isDescriptionExpanded
                  ? hiddenDescription.current.offsetHeight
                  : DESCRIPTION_CUTOFF_HEIGHT,
              }}
            />
            <div className="job-description__hidden-text-container">
              <div
                dangerouslySetInnerHTML={{ __html: jobDescription }}
                ref={hiddenDescription}
                aria-hidden
              />
            </div>
            {isDescriptionCutOff && (
              <button
                className={`job-description__toggle-button--${
                  isDescriptionExpanded ? "expanded" : "not-expanded"
                }`}
                onClick={toggleDescriptionExpansion}
              >
                {isDescriptionExpanded
                  ? i18n.t("description.showLess")
                  : i18n.t("description.showMore")}
              </button>
            )}
          </div>
          {benefits && (
            <div
              className="job-description__benefits"
              dangerouslySetInnerHTML={{ __html: benefits }}
              ref={benefitsRef}
            />
          )}
          <div className="call-to-action__desktop">{getCallToAction()}</div>
        </div>
      </div>
      {redirectUrl && (
        <RedirectScreen redirectUrl={redirectUrl} customer={customer} />
      )}
    </div>
  );
};

export default JobOverviewPage;
