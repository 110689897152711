import { route } from "preact-router";
import i18n from "../i18n";
import { notifyError } from "../utils";

const fetchForm = ({ customerSlug, atsJobPostId, dispatch }) => {
  fetch(`/api/jobPost/${customerSlug}/${atsJobPostId}`)
    .then(res => {
      if (!res.ok) {
        throw new Error();
      }
      return res.json();
    })
    .then(({ customer, jobPost }) => {
      i18n.changeLanguage(jobPost.language);
      dispatch({ type: "FETCH_DATA", payload: { customer, jobPost } });
    })
    .catch(e => {
      notifyError(e);
      route("/", true);
    });
};

export default fetchForm;
