import { h } from "preact";
import { useContext } from "preact/hooks";
import i18n from "i18next";
import { Store } from "../../store";
import { validateField, getFormFieldClass } from "../../utils";
import { updateApplicationInput } from "../../actionCreators";

const CandidateDescriptionField = ({ field }) => {
  const { state, dispatch } = useContext(Store);
  const fieldData = state.application.profileInfo.candidateDescription;

  const formFieldClass = getFormFieldClass(fieldData);
  const handleInput = event => {
    const { value } = event.target;
    const error = validateField({ value, validation: field.validation });
    updateApplicationInput({
      name: "candidateDescription",
      value,
      error,
      dispatch
    });
  };
  const handleBlur = () => {
    dispatch({
      type: "SHOW_VALIDATION",
      payload: { name: "candidateDescription" }
    });
  };

  return (
    <div
      className={`form-field textarea candidate-description ${formFieldClass}`}
    >
      <div className="form-field__label-group">
        <label className="form-field__label" htmlFor="upload-cv-button">
          {field.label}
        </label>
        <span>
          {fieldData.value.length} / {field.validation.maxLength}{" "}
          {i18n.t("form.characters")}
        </span>
      </div>
      <textarea
        value={fieldData.value}
        name="candidateDescription"
        rows="5"
        onInput={handleInput}
        onBlur={handleBlur}
        placeholder={i18n.t("form.placeholder.candidateDescription")}
      />
      {fieldData.showValidation && (
        <p className="form-field__error">{fieldData.error}</p>
      )}
    </div>
  );
};

export default CandidateDescriptionField;
