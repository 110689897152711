export const fileExtensions = {
  cv: [".docx", ".pdf"],
  avatar: [".png", ".jpg", ".jpeg"],
};
export const mimeTypes = {
  cv: [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
  ],
  avatar: ["image/png", "image/jpeg"],
};

// 10 MB
export const maxFileSize = 10 * 1024 * 1024;
