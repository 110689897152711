import * as Sentry from "@sentry/browser";

import updateApplicationInput from "./updateApplicationInput";
import updateLinkedInUrl from "./updateLinkedInUrl";
import saveAvatar from "./saveAvatar";

const fetchLinkedInProfile = ({ dispatch, profileFields }) => {
  const code = window.linkedInCode;
  delete window.linkedInCode;
  if (code) {
    const redirectUri = `${window.location.protocol}//${window.location.host}/linkedInAuth`;
    const body = JSON.stringify({
      code,
      redirectUri,
    });

    // Fetch LinkedIn User Data from server
    fetch(`/api/linkedIn/me/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body,
    })
      .then((res) => res.json())
      .then((res) => {
        updateLinkedInUrl({ dispatch, linkedInUrl: res.profileUrl });
        for (const field of profileFields) {
          if (res[field.name]) {
            updateApplicationInput({
              name: field.name,
              value: res[field.name],
              showValidation: true,
              dispatch,
            });
          }
        }
        // Set avatar
        if (res.avatar) {
          fetch(res.avatar)
            .then((fileRes) => {
              if (!fileRes.ok) {
                throw new Error(fileRes.statusText);
              }
              return fileRes.blob();
            })
            .then((blob) => {
              const file = new Blob([blob], { type: res.mediaType });
              const filename = `linkedinAvatar.${res.mediaType.split("/")[1]}`;
              saveAvatar({
                dispatch,
                file,
                filename,
                id: "linkedIn",
                thumbnail: res.avatar,
              });
              dispatch({
                type: "UPDATE_CONFIRMED_AVATAR",
                payload: "linkedIn",
              });
              // }}
            })
            .catch((error) => {
              Sentry.captureException(error);
              console.error(error);
            });
        }
      });
  }
};

export default fetchLinkedInProfile;
