import i18n from "i18next";
import { h } from "preact";

const TitleBlockPart = ({ backgroundImage, logoUrl, slug, customerColor }) => {
  const title = i18n.t("description.title");
  return (
    <div
      className={`title-block title-block__${slug} ${!backgroundImage &&
        `title-block__no-image`}`}
      style={{
        background: `${title &&
          "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),"} ${
          backgroundImage
            ? `url(${backgroundImage}) center center/cover`
            : `${!backgroundImage && customerColor}`
        }`,
      }}
    >
      {logoUrl && (
        <div className="title-block__logo-wrapper">
          <div
            className="title-block__logo"
            style={{
              background: customerColor || null,
            }}
          >
            <div
              style={{
                backgroundSize: customerColor ? "90%" : null,
                borderRadius: customerColor ? "6px" : null,
                backgroundImage: `url(${logoUrl})`,
              }}
            />
          </div>
          <h1>{i18n.t("description.title")}</h1>
        </div>
      )}
    </div>
  );
};

export default TitleBlockPart;
