import { h } from "preact";
import Router from "preact-router";
import { ToastContainer } from "react-toastify";
import i18n from "i18next";
import { StoreProvider } from "./store";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import LinkedInRedirectPage from "./pages/LinkedInRedirectPage/LinkedInRedirectPage";
import Main from "./Main";
import SecretIndexPage from "./pages/SecretIndexPage/SecretIndexPage";
import NotFound from "./pages/NotFoundPage/NotFoundPage";
import "./styles/reset.scss";
import "./styles/base.scss";
import "./styles/generalStylesLegacy.scss";

import "react-toastify/dist/ReactToastify.css";
import CookiePolicyPage from "./pages/CookiePolicyPage/CookiePolicyPage";

const App = ({ preloadedState, url }) => {
  if (preloadedState) {
    i18n.changeLanguage(preloadedState.form.jobPost.language);
  }

  return (
    <ErrorBoundary>
      <StoreProvider preloadedState={preloadedState}>
        <Router url={url}>
          <LinkedInRedirectPage path="/linkedInAuth" />
          <Main path="/:customerSlug/:atsJobPostId/:locationSlug?/:titleSlug?/:status?" />
          <SecretIndexPage path="/secret-index-page" />
          <CookiePolicyPage path="/cookie-policy" />
          <NotFound default path="/" />
        </Router>
      </StoreProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
    </ErrorBoundary>
  );
};

export default App;
