const { joinCitySlugs } = require("../utils");

const getPagePath = (state) => {
  const { customer, jobPost } = state.form;
  const { titleSlug, locations } = jobPost;
  const citySlug = joinCitySlugs(locations);
  return `/${customer.slug}/${jobPost.atsJobPostId}/${citySlug}/${titleSlug}`;
};

export default getPagePath;
