import { h, createContext } from "preact";
import { useReducer } from "preact/hooks";
import { getInitialApplicationData, getInitialAvatar } from "./utils";
import reducer from "./reducer";

export const Store = createContext();

export const StoreProvider = ({ children, preloadedState = {} }) => {
  const { form } = preloadedState;
  const application = getInitialApplicationData(preloadedState.form);
  const avatar = getInitialAvatar();
  const tracking = {
    isInitialized: false,
    cookieConsentStatus:
      typeof window !== "undefined" &&
      (localStorage.getItem("cookieConsentStatus") ?? "undecided"),
  };
  const initialState = {
    form,
    application,
    avatar,
    tracking,
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <Store.Provider value={value}>{children}</Store.Provider>;
};
