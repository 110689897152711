import { h } from "preact";
import { useContext } from "preact/hooks";
import i18n from "i18next";
import { Store } from "../../store";
import { validateField, getFormFieldClass } from "../../utils";
import { updateApplicationInput } from "../../actionCreators";

const TextareaField = ({ field }) => {
  const { state, dispatch } = useContext(Store);
  const { answers, profileInfo } = state.application;

  const fieldId = field.atsQuestionId || field.name;
  const fieldData = answers[field.atsQuestionId] || profileInfo[field.name];

  const formFieldClass = getFormFieldClass(fieldData);
  const handleInput = event => {
    const { value } = event.target;
    const error = validateField({ value, validation: field.validation });
    updateApplicationInput({
      name: field.name,
      atsQuestionId: field.atsQuestionId,
      value,
      error,
      dispatch
    });
  };
  const handleBlur = () => {
    dispatch({
      type: "SHOW_VALIDATION",
      payload: { name: field.name, atsQuestionId: field.atsQuestionId }
    });
  };

  return (
    <div className={`form-field textarea ${formFieldClass}`}>
      <label htmlFor={fieldId} className="form-field__label">
        {field.label}
      </label>
      <textarea
        value={fieldData.value}
        name={fieldId}
        rows="3"
        onInput={handleInput}
        onBlur={handleBlur}
      />
      {fieldData.showValidation && (
        <p className="form-field__error">{fieldData.error}</p>
      )}
    </div>
  );
};

export default TextareaField;
