// Wrap FileReader API as a promise
const readFileAsBase64 = file =>
  new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = event => {
      const avatarUrl = event.target.result;
      resolve(avatarUrl);
    };
    reader.readAsDataURL(file);
  });

export default readFileAsBase64;
