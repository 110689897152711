import { h, Fragment } from "preact";
import { createPortal } from "preact/compat";
import { useState, useContext } from "preact/hooks";
import i18n from "i18next";
import { Store } from "../../store";
import { saveCoverLetter } from "../../actionCreators";
import Popup from "../Popup/Popup";
import CoverLetterPopup from "../CoverLetterPopup";
import dropboxIconSvg from "../../../assets/images/dropboxIcon.svg";
import googleDriveIconSvg from "../../../assets/images/googleDriveIcon.svg";
import uploadIconSvg from "../../../assets/images/uploadIcon.svg";

const CoverLetterField = () => {
  const { state, dispatch } = useContext(Store);
  const [popupOpen, setPopupOpen] = useState(false);
  const { coverLetter } = state.application;
  const { applyForm } = state.form.jobPost;
  const enabled = applyForm.coverLetter?.enabled;
  const required = applyForm.coverLetter?.required;
  if (!enabled) return;

  const handleClick = event => {
    event.preventDefault();
    setPopupOpen(true);
  };

  const updateCoverLetter = ({ file, filename }) => {
    saveCoverLetter({ file, filename, dispatch });
  };

  return (
    <div className="form-field cv-field">
      {popupOpen &&
        createPortal(
          <Popup setPopupOpen={setPopupOpen}>
            <CoverLetterPopup updateCoverLetter={updateCoverLetter} />
          </Popup>,
          // Portal into this div to let the modal get the right position and width
          document.querySelector(".form-screen__form-section")
        )}
      <div className="form-field__label-group">
        <label
          className="form-field__label"
          htmlFor="upload-coverletter-button"
        >
          {i18n.t("label.coverLetter")}
        </label>
        <span>
          {required ? i18n.t("form.required") : i18n.t("form.optional")}
        </span>
      </div>
      <button
        id="upload-coverletter-button"
        className="cv-field__button"
        onClick={handleClick}
      >
        {coverLetter.file ? (
          <Fragment>
            <span className="cv-field__file-name">{coverLetter.filename}</span>
            <span className="checkbox-icon" />
          </Fragment>
        ) : (
          <Fragment>
            <div>{i18n.t("form.cvFieldUpload")}</div>
            <span className="cv-field__social-icons">
              <img src={dropboxIconSvg} alt="" />
              <img src={googleDriveIconSvg} alt="" />
              <img src={uploadIconSvg} alt="" />
            </span>
          </Fragment>
        )}
      </button>
      {coverLetter.file && (
        <span className="cv-field__hint">{i18n.t("form.cvFieldHint")}</span>
      )}
    </div>
  );
};

export default CoverLetterField;
