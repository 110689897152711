import { getInitialApplicationData } from "./utils";

const form = (state, action) => {
  switch (action.type) {
    case "FETCH_DATA": {
      const formData = action.payload;
      return formData;
    }
    default:
      return state;
  }
};

const application = (state, action) => {
  switch (action.type) {
    case "FETCH_DATA": {
      const formData = action.payload;
      if (!formData) {
        return state;
      }
      return getInitialApplicationData(formData);
    }

    case "UPDATE_APPLICATION_INPUT": {
      const { name, atsQuestionId, value, error } = action.payload;
      if (atsQuestionId) {
        return {
          ...state,
          answers: {
            ...state.answers,
            [atsQuestionId]: {
              ...state.answers[atsQuestionId],
              showValidation:
                action.payload.showValidation ??
                state.answers[atsQuestionId].showValidation,
              value,
              error,
            },
          },
        };
      }
      // Else it's a profile field
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          [name]: {
            ...state.profileInfo[name],
            showValidation:
              action.payload.showValidation ??
              state.profileInfo[name].showValidation,
            value,
            error,
          },
        },
      };
    }

    case "SHOW_VALIDATION": {
      const { name, atsQuestionId } = action.payload;
      if (atsQuestionId) {
        return {
          ...state,
          answers: {
            ...state.answers,
            [atsQuestionId]: {
              ...state.answers[atsQuestionId],
              showValidation: true,
            },
          },
        };
      }
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          [name]: { ...state.profileInfo[name], showValidation: true },
        },
      };
    }
    case "UPDATE_CV": {
      return { ...state, cv: action.payload };
    }
    case "UPDATE_COVER_LETTER": {
      return { ...state, coverLetter: action.payload };
    }
    case "UPDATE_FACEBOOK_USER_ID": {
      return { ...state, facebookUserId: action.payload };
    }
    case "UPDATE_LINKEDIN_URL": {
      return { ...state, linkedInUrl: action.payload };
    }
    case "SUBMIT_FORM": {
      return { ...state, isSubmitting: true };
    }
    case "SUBMIT_FORM_SUCCESS": {
      const applicationId = action.payload;
      return {
        ...state,
        isSubmitting: false,
        isSubmitted: true,
        error: null,
        id: applicationId,
      };
    }
    case "SUBMIT_FORM_FAILURE": {
      const error = action.payload;
      return { ...state, isSubmitting: false, error };
    }
    case "UPDATE_TERMS_ACCEPTED": {
      return { ...state, termsAccepted: action.payload };
    }
    default:
      return state;
  }
};

function avatar(state, action) {
  switch (action.type) {
    case "UPDATE_AVATAR_DATA": {
      const { id, file, filename, thumbnail } = action.payload;

      if (state.avatars[id]) {
        return {
          ...state,
          avatars: {
            ...state.avatars,
            [id]: {
              ...state.avatars[id],
              file,
              thumbnail,
              filename,
            },
          },
        };
      }
      return state;
    }
    case "UPDATE_CONFIRMED_AVATAR": {
      const id = action.payload;
      // Make sure that the id passed exists or wants to be unset
      if (state.avatars[id] || id === null) {
        return {
          ...state,
          confirmedAvatar: id,
        };
      }
      return state;
    }
    default:
      return state;
  }
}

function tracking(state, action) {
  switch (action.type) {
    case "INITIALIZE_TRACKING": {
      return { ...state, isInitialized: true };
    }
    case "SET_COOKIE_CONSENT_STATUS": {
      return { ...state, cookieConsentStatus: action.payload };
    }
    default:
      return state;
  }
}

const combineReducers = (reducer) => {
  return (state = {}, action) => {
    const keys = Object.keys(reducer);
    const nextReducers = {};
    for (let i = 0; i < keys.length; i += 1) {
      const invoke = reducer[keys[i]](state[keys[i]], action);
      nextReducers[keys[i]] = invoke;
    }
    return nextReducers;
  };
};

export default combineReducers({
  form,
  application,
  avatar,
  tracking,
});
