import { h } from "preact";
import i18n from "i18next";
import DropboxChooser from "react-dropbox-chooser";
import { isFacebookInAppBrowser } from "../../utils";
import { fileExtensions, maxFileSize } from "../../config";
import dropboxIconSvg from "../../../assets/images/dropboxIcon.svg";

const UploadDropbox = ({
  handleUpload,
  handleError,
  fieldName,
  avatarTypeId
}) => {
  if (isFacebookInAppBrowser()) return null;
  const handleSuccess = ([data]) => {
    const { link, name: filename, bytes } = data;
    if (bytes > maxFileSize) {
      handleError(
        new Error(
          i18n.t("form.validation.maxFileSize", {
            maxSizeInMb: maxFileSize / (1024 * 1024)
          })
        )
      );
      return;
    }
    fetch(link)
      .then(res => {
        if (!res.ok) {
          throw new Error(i18n.t("form.validation.uploadFailed"));
        }
        return res.blob();
      })
      .then(blob => {
        const file = new Blob([blob], {
          type: "image/jpeg"
        });
        handleUpload({ file, filename, avatarTypeId });
      })
      .catch(handleError);
  };

  return (
    <DropboxChooser
      appKey={process.env.DROPBOX_CLIENT_ID}
      success={handleSuccess}
      extensions={fileExtensions[fieldName]}
      linkType="direct"
    >
      <div className="popup__upload-item social social-db">
        <div className="popup__upload-icon">
          <img src={dropboxIconSvg} alt="" />
        </div>
        <p className="popup__upload-text">
          {i18n.t("cvPopup.uploadFromNetwork", {
            network: "Dropbox"
          })}
        </p>
      </div>
    </DropboxChooser>
  );
};

export default UploadDropbox;
