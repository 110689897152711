import { h, Fragment } from "preact";
import { useContext } from "preact/hooks";
import { Store } from "../../store";
import TitleBlockPart from "./TitleBlockPart";

import "./TitleBlock.scss";

const TitleBlock = () => {
  const { state } = useContext(Store);

  const { customer, jobPost } = state.form;
  const { images, category } = jobPost;
  const { logoUrl } = customer;

  const desktopBackgroundImage =
    images[category]?.portrait ||
    images[category]?.backgroundSmall ||
    images.portrait ||
    images.backgroundSmall;
  const mobileBackgroundImage = images.backgroundSmall;

  return (
    <Fragment>
      <TitleBlockPart
        backgroundImage={desktopBackgroundImage}
        slug="desktop"
        customerColor={customer.customerColor}
        logoUrl={logoUrl}
      />
      <TitleBlockPart
        backgroundImage={mobileBackgroundImage}
        slug="mobile"
        customerColor={customer.customerColor}
        logoUrl={logoUrl}
      />
    </Fragment>
  );
};

export default TitleBlock;
