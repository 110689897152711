import i18n from "../i18n";

const getLabel = (name) => {
  switch (name) {
    case "firstName": {
      return i18n.t("label.firstName");
    }
    case "lastName": {
      return i18n.t("label.lastName");
    }
    case "name": {
      return i18n.t("label.name");
    }
    case "email": {
      return i18n.t("label.email");
    }
    case "countryCode": {
      return i18n.t("label.countryCode");
    }
    case "displayedPhoneNumber": {
      return i18n.t("label.phone");
    }
    case "phone": {
      return i18n.t("label.phone");
    }
    case "gender": {
      return i18n.t("label.gender");
    }
    case "age": {
      return i18n.t("label.age");
    }
    case "cv": {
      return i18n.t("label.cv");
    }
    case "country": {
      return i18n.t("label.country");
    }
    case "candidateDescription": {
      return i18n.t("label.candidateDescription");
    }
    default: {
      return name;
    }
  }
};

export default getLabel;
