import { h } from "preact";
import "./Spinner.scss";

const Spinner = () => {
  return (
    <div className="spinner-wrapper">
      <div className="spinner">
        <span />
      </div>
    </div>
  );
};

export default Spinner;
