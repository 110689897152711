import { h } from "preact";
import { createPortal } from "preact/compat";
import { useContext, useState } from "preact/hooks";
import i18n from "i18next";
import { Store } from "../../store";
import Popup from "../Popup/Popup";
import CvPopup from "../CvPopup/CvPopup";
import AvatarPopup from "../AvatarPopup/AvatarPopup";
import { submitAvatar, submitCv } from "../../actionCreators";

const ProfileStrengthImprovement = ({ hasCv, hasAvatar }) => {
  const [cvPopupOpen, setCvPopupOpen] = useState(false);
  const [avatarPopupOpen, setAvatarPopupOpen] = useState(false);
  const { state, dispatch } = useContext(Store);
  const { id } = state.application;
  const { avatars } = state.avatar;

  return (
    <div className="profile__improve">
      {cvPopupOpen &&
        createPortal(
          <Popup setPopupOpen={setCvPopupOpen}>
            <CvPopup
              updateCv={({ file, filename }) =>
                submitCv({ file, filename, applicationId: id, dispatch })
              }
            />
          </Popup>,
          document.body
        )}
      {avatarPopupOpen &&
        createPortal(
          <Popup setPopupOpen={setAvatarPopupOpen}>
            <AvatarPopup
              updateFormFields={false}
              updateAvatar={({ avatarTypeId }) => {
                const avatarData = avatars[avatarTypeId];
                submitAvatar({
                  applicationId: id,
                  dispatch,
                  avatarData,
                  avatarTypeId
                });
              }}
            />
          </Popup>,
          document.body
        )}
      <div className="profile__improve-wrapper">
        <h2>{i18n.t("confirmation.improveTitle")}</h2>
        <ul>
          <li>
            <button
              onClick={() => setAvatarPopupOpen(true)}
              className={`profile__improve-item ${
                hasAvatar ? "completed" : ""
              }`}
            >
              <span className="percentage">+10%</span>
              <div className="profile__improve-info">
                <h3>{i18n.t("confirmation.uploadAvatar")}</h3>
                <span>
                  {hasAvatar
                    ? i18n.t("confirmation.uploadNewAvatarButton")
                    : i18n.t("confirmation.uploadAvatarButton")}
                </span>
              </div>
              <span className="upload" />
            </button>
          </li>
          <li>
            <button
              onClick={() => setCvPopupOpen(true)}
              className={`profile__improve-item ${hasCv ? "completed" : ""}`}
            >
              <span className="percentage">+10%</span>
              <div className="profile__improve-info">
                <h3>{i18n.t("confirmation.uploadCv")}</h3>
                <span>
                  {hasCv
                    ? i18n.t("confirmation.uploadNewCvButton")
                    : i18n.t("confirmation.uploadCvButton")}
                </span>
              </div>
              <span className="upload" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProfileStrengthImprovement;
