import trackLinkedInConversion from "./trackLinkedInConversion";
import { FACEBOOK_PIXEL_ID } from "../constants";

const trackInitiatedCheckout = (customer) => {
  const { linkedInConfigs = [] } = customer;
  window.gtag("event", "initiate-application", {
    event_category: "Application",
  });
  if (FACEBOOK_PIXEL_ID) {
    fbq("trackSingle", FACEBOOK_PIXEL_ID, "InitiateCheckout");
  }
  linkedInConfigs.forEach(({ partnerId, initiateCheckoutId }) => {
    trackLinkedInConversion({ partnerId, conversionId: initiateCheckoutId });
  });
};

export default trackInitiatedCheckout;
