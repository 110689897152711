const initializeLinkedInPixel = linkedInConfigs => {
  if (!linkedInConfigs) return;
  const partnerIds = linkedInConfigs.map(({ partnerId }) => partnerId);
  window._linkedin_data_partner_ids = partnerIds;
  /* eslint-disable */
  var s = document.getElementsByTagName("script")[0];
  var b = document.createElement("script");
  b.type = "text/javascript";
  b.async = true;
  b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
  s.parentNode.insertBefore(b, s);
  /* eslint-enable */
};

export default initializeLinkedInPixel;
