import { h } from "preact";
import { useRef } from "preact/hooks";
import i18n from "i18next";
import GooglePicker from "react-google-picker";
import { isFacebookInAppBrowser } from "../../utils";
import { mimeTypes, maxFileSize } from "../../config";
import googleDriveIconSvg from "../../../assets/images/googleDriveIcon.svg";

const UploadGoogleDrive = ({
  handleUpload,
  handleError,
  fieldName,
  avatarTypeId
}) => {
  const googleOauthToken = useRef();
  if (isFacebookInAppBrowser()) return null;

  // We first get the oauth token, then we fetch the file using that token to authenticate the request
  const handleAuthenticate = token => {
    googleOauthToken.current = token;
  };

  const handleChange = ({ docs }) => {
    // The function is sometimes called prematurely without any data, just ignore
    if (!docs) {
      return;
    }
    if (!googleOauthToken.current) {
      handleError(new Error(i18n.t("form.validation.googleAuthFailed")));
      return;
    }
    const { id, name, type, sizeBytes } = docs[0];

    if (sizeBytes > maxFileSize) {
      handleError(
        new Error(
          i18n.t("form.validation.maxFileSize", {
            maxSizeInMb: maxFileSize / (1024 * 1024)
          })
        )
      );
      return;
    }

    const mimeType = type === "document" ? "application/pdf" : docs[0].mimeType;
    const filename = type === "document" ? `${name}.pdf` : name;
    // Depending on whether the selected document is a file or a google doc, they have to be fetched from different endpoints.
    const fileUrl =
      type === "document"
        ? `https://www.googleapis.com/drive/v3/files/${id}/export?mimeType=${mimeType}&alt=media`
        : `https://www.googleapis.com/drive/v3/files/${id}?alt=media`;

    fetch(fileUrl, {
      headers: {
        Authorization: `Bearer ${googleOauthToken.current}`
      }
    })
      .then(res => {
        if (!res.ok) {
          throw new Error(i18n.t("form.validation.uploadFailed"));
        }
        return res.blob();
      })
      .then(blob => {
        const file = new Blob([blob], { type: mimeType });
        handleUpload({ file, filename, avatarTypeId });
      })
      .catch(handleError);
  };

  const handleAuthFailure = () => {
    handleError(new Error(i18n.t("form.validation.googleAuthFailed")));
  };

  const viewId = fieldName === "avatar" ? "DOCS_IMAGES" : "DOCS";

  return (
    <GooglePicker
      clientId={process.env.GOOGLE_CLIENT_ID}
      onAuthenticate={handleAuthenticate}
      onAuthFailed={handleAuthFailure}
      mimeTypes={mimeTypes[fieldName]}
      scope={["https://www.googleapis.com/auth/drive.file"]}
      navHidden
      createPicker={(google, oauthToken) => {
        new window.google.picker.PickerBuilder()
          .addView(new google.picker.DocsView(google.picker.ViewId[viewId]))
          .setOAuthToken(oauthToken)
          .setDeveloperKey(process.env.GOOGLE_DEVELOPER_KEY)
          .setAppId(process.env.GOOGLE_DEVELOPER_KEY.split("-")[0])
          .setCallback(handleChange)
          .build()
          .setVisible(true);
      }}
    >
      <div className="popup__upload-item social social-gd">
        <div className="popup__upload-icon">
          <img src={googleDriveIconSvg} alt="" />
        </div>
        <p className="popup__upload-text">
          {i18n.t("cvPopup.uploadFromNetwork", {
            network: "Google Drive"
          })}
        </p>
      </div>
    </GooglePicker>
  );
};

export default UploadGoogleDrive;
