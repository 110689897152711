import { h } from "preact";
import { useContext } from "preact/hooks";
import { Store } from "../../store";
import { validateField, getFormFieldClass } from "../../utils";
import { updateApplicationInput } from "../../actionCreators";

const RadioField = ({ field }) => {
  const { state, dispatch } = useContext(Store);
  const { answers, profileInfo } = state.application;

  const fieldData = answers[field.atsQuestionId] || profileInfo[field.name];

  const handleInput = (event) => {
    const { value } = event.target;

    const error = validateField({ value, validation: field.validation });
    updateApplicationInput({
      name: field.name,
      atsQuestionId: field.atsQuestionId,
      value,
      error,
      dispatch,
    });
  };

  const formFieldClass = getFormFieldClass(fieldData);

  return (
    <div className={`form-field ${formFieldClass}`}>
      <span className="form-field__label">{field.label}</span>
      {field.options.map((option) => (
        <label
          htmlFor={`${field.atsQuestionId || field.name}_${option.id}`}
          className="form-field__radio-group"
        >
          <input
            onClick={handleInput}
            id={`${field.atsQuestionId || field.name}_${option.id}`}
            type="radio"
            name={field.atsQuestionId || field.name}
            value={option.id}
            checked={fieldData.value === option.id}
          />
          <span className="form-field__radio-group__status" />
          <span className="form-field__radio-group__text">{option.name}</span>
        </label>
      ))}
      {fieldData.showValidation && (
        <p className="form-field__error">{fieldData.error}</p>
      )}
    </div>
  );
};

export default RadioField;
