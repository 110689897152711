import { h, cloneElement } from "preact";
import { useState, useLayoutEffect } from "preact/hooks";

const Popup = ({ children, setPopupOpen }) => {
  const [transitionPos, setTransitionPos] = useState("closed");
  useLayoutEffect(() => {
    setTransitionPos("open");
  }, []);
  // Close popup by first triggering transition and then closing the popup when transition is finished
  const closePopupWithTransition = () => {
    setTransitionPos("closed");
  };
  const handleTransitionEnd = () => {
    if (transitionPos === "closed") {
      setPopupOpen(false);
    }
  };

  return (
    <div
      onTransitionEnd={handleTransitionEnd}
      className={`popup ${transitionPos === "open" ? "active" : ""}`}
    >
      <div
        role="presentation"
        className="popup__overlay"
        onClick={() => closePopupWithTransition()}
        onKeyDown={(event) => {
          if (event.keyCode === 27) {
            closePopupWithTransition();
          }
        }}
      />
      {cloneElement(children, { closePopupWithTransition })}
    </div>
  );
};

export default Popup;
