const submitCv = ({ file, filename, applicationId, dispatch }) => {
  const formData = new FormData();
  formData.append("cv", file);
  fetch(`/api/application/${applicationId}/updateCv`, {
    method: "PATCH",
    body: formData
  })
    .then(res => {
      if (!res.ok) {
        return res.text().then(message => {
          throw new Error(message);
        });
      }
      return res;
    })
    .then(() => {
      dispatch({
        type: "UPDATE_CV",
        payload: { file, filename }
      });
    });
  // TODO: Error handling
};

export default submitCv;
