import { route } from "preact-router";
import * as Sentry from "@sentry/browser";
import { getPagePath } from "../selectors";

const submitApplication = (state, dispatch) => {
  const { application, form, avatar } = state;
  const { avatars, confirmedAvatar } = avatar;
  const id = form.jobPost._id;
  const { profileFields, questionFields } = form.jobPost.applyForm;
  const { cv, coverLetter, linkedInUrl, facebookUserId } = application;

  dispatch({ type: "SUBMIT_FORM" });

  const profileInfo = Object.fromEntries(
    profileFields.map((field) =>
      field.name === "phone"
        ? [
            field.name,
            `${application.profileInfo.countryCode.value}${application.profileInfo.displayedPhoneNumber.value}`,
          ]
        : [field.name, application.profileInfo[field.name].value]
    )
  );

  const answers = Object.fromEntries(
    questionFields.map((field) => [
      field.atsQuestionId,
      {
        value: application.answers[field.atsQuestionId].value,
        type: field.type,
        questionType: field.questionType,
      },
    ])
  );

  const formData = new FormData();
  formData.append(
    "data",
    JSON.stringify({ profileInfo, answers, linkedInUrl, facebookUserId })
  );

  if (cv.file) {
    formData.append("cv", cv.file, cv.filename);
  }

  if (coverLetter.file) {
    formData.append("coverLetter", coverLetter.file, coverLetter.filename);
  }

  // Append Avatar
  if (avatars[confirmedAvatar]) {
    formData.append(
      "avatar",
      avatars[confirmedAvatar].file,
      avatars[confirmedAvatar].filename
    );
  }

  fetch(`/api/application/${id}`, {
    method: "POST",
    body: formData,
  })
    .then((res) => {
      if (!res.ok) {
        return res.text().then((message) => {
          throw new Error(message);
        });
      }
      return res;
    })
    .then((res) => res.json())
    .then(({ applicationId }) => {
      sessionStorage.setItem(`applicationId-${id}`, applicationId);
      dispatch({ type: "SUBMIT_FORM_SUCCESS", payload: applicationId });
      route(`${getPagePath(state)}/thanks`);
    })
    .catch((error) => {
      Sentry.captureException(error);
      dispatch({ type: "SUBMIT_FORM_FAILURE", payload: error.message });
    });
};

export default submitApplication;
