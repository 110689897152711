import trackLinkedInConversion from "./trackLinkedInConversion";
import { FACEBOOK_PIXEL_ID } from "../constants";

const trackRegistration = (customer) => {
  const { linkedInConfigs = [] } = customer;
  window.gtag("event", "submit-application", {
    event_category: "Application",
  });
  if (FACEBOOK_PIXEL_ID) {
    fbq("trackSingle", FACEBOOK_PIXEL_ID, "CompleteRegistration");
  }
  linkedInConfigs.forEach(({ partnerId, conversionId }) => {
    trackLinkedInConversion({ partnerId, conversionId });
  });
};

export default trackRegistration;
