const submitAvatar = ({
  applicationId,
  dispatch,
  avatarData,
  avatarTypeId
}) => {
  const formData = new FormData();

  // Append Avatar
  if (avatarData) {
    formData.append("avatar", avatarData.file);
  }

  fetch(`/api/application/${applicationId}/updateAvatar`, {
    method: "PATCH",
    body: formData
  })
    .then(res => {
      if (!res.ok) {
        return res.text().then(message => {
          throw new Error(message);
        });
      }
      return res;
    })
    .then(() => {
      dispatch({
        type: "UPDATE_CONFIRMED_AVATAR",
        payload: avatarTypeId
      });
    });
  // TODO: Error handling
};

export default submitAvatar;
