import { h } from "preact";

import "./CookiePolicyPage.scss";

const CookiePolicyPage = () => {
  const cookieMap = {
    Google: [
      {
        cookieName: "_ga",
        cookieDuration: "2 years",
        cookieDescription:
          "This cookie is typically written to the browser upon the first visit to the Websites. If the cookie has been deleted by the browser operator, and the browser subsequently visits the website, a new _ga cookie is written with a different unique ID. In most cases, this cookie is used to understand whether a visitor is a single time visitor or a repeat visitor to the Websites and it is updated with each page view. Additionally, this cookie is provided with a unique ID that Google Analytics uses to ensure both the validity and accessibility of the cookie as an extra security measure.",
      },
      {
        cookieName: "_gid",
        cookieDuration: "24 hours",
        cookieDescription:
          "This cookie stores a unique user session identifier.",
      },
      {
        cookieName: "_gat",
        cookieDuration: "1 minute",
        cookieDescription:
          "Used to throttle request rate to reduce the load on Google's servers.",
      },
      {
        cookieName: "_guid",
        cookieDuration: "6 month",
        cookieDescription:
          "This is a Google Analytics cookie to analyse the performance of website.",
      },
    ],
    Facebook: [
      {
        cookieName: "_fbp",
        cookieDuration: "3 months",
        cookieDescription:
          "It enables measuring, optimising and the building of audiences for  advertising campaigns which appear on Facebook. In particular it enables one to see how our users move between devices when accessing the the website and Facebook, to ensure that the Facebook advertising is seen by the users most likely to be interested in such advertising by analysing which content a user has viewed and interacted with on the website.",
      },
      {
        cookieName: "_fbc",
        cookieDuration: "3 months",
        cookieDescription:
          "It enables measuring, optimising and the building of audiences for  advertising campaigns which appear on Facebook. In particular it enables one to see how our users move between devices when accessing the the website and Facebook, to ensure that the Facebook advertising is seen by the users most likely to be interested in such advertising by analysing which content a user has viewed and interacted with on the website.",
      },
    ],
    Linkedin: [
      {
        cookieName: "_lipt",
        cookieDuration: "30 days",
        cookieDescription:
          "These cookies enable behavioral advertising and analytics by LinkedIn.",
      },
      {
        cookieName: "li_oatml",
        cookieDuration: "30 days maximum",
        cookieDescription:
          "Member indirect indentifier for conversion tracking, retargeting, analytics",
      },
      {
        cookieName: "lidc",
        cookieDuration: "1 day",
        cookieDescription: "Used for routing.",
      },
      {
        cookieName: "lissc",
        cookieDuration: "1 year",
        cookieDescription:
          "Used by the social networking service, LinkedIn, for tracking the use of embedded services.",
      },
    ],
    Sentry: [
      {
        cookieName: "amplitude_id",
        cookieDuration: "10 years",
        cookieDescription:
          "Used by sentry for tracking all errors logged to sentry",
      },
    ],
  };

  return (
    <div className="cookie-policy__container">
      <h1 className="cookie-policy__heading">Cookie Policy</h1>
      <div>
        {Object.entries(cookieMap).map(([cookieOwner, cookieList]) => {
          return (
            <div>
              <h2 className="cookie-policy__sub-heading">{cookieOwner}</h2>
              {cookieList.map((cookie) => {
                return (
                  <div>
                    <h3 className="cookie-policy__cookie-name">
                      {cookie.cookieName}
                      <br />
                      <small>{cookie.cookieDuration}</small>
                    </h3>
                    <p className="cookie-policy__cookie-description">
                      {cookie.cookieDescription}
                    </p>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CookiePolicyPage;
