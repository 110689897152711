import initializeFacebookPixel from "./initializeFacebookPixel";
import initializeGoogleAnalytics from "./initializeGoogleAnalytics";
import initializeHotjar from "./initializeHotjar";
import initializeLinkedInPixel from "./initializeLinkedInPixel";

const initializeTracking = ({ jobPost, customer, dispatch }) => {
  initializeFacebookPixel({ jobPost, customer });
  initializeLinkedInPixel(customer.linkedin);
  initializeHotjar();
  initializeGoogleAnalytics({ jobPost, customer }).then(() => {
    dispatch({ type: "INITIALIZE_TRACKING" });
  });
};

export default initializeTracking;
