import { h } from "preact";
import TextField from "./TextField";
import TextareaField from "./TextareaField";
import CheckboxField from "./CheckboxField";
import RadioField from "./RadioField";
import CountrySelectField from "./CountrySelectField";
import CandidateDescriptionField from "./CandidateDescriptionField";
import "./FormField.scss";
import PhoneNumberField from "./PhoneNumberField";

const FormField = ({ field }) => {
  if (field.name === "country") {
    return <CountrySelectField field={field} />;
  }
  if (field.name === "candidateDescription") {
    return <CandidateDescriptionField field={field} />;
  }
  if (field.type === "textarea") {
    return <TextareaField field={field} />;
  }
  if (field.type === "checkbox") {
    return <CheckboxField field={field} />;
  }
  if (field.type === "radio") {
    return <RadioField field={field} />;
  }
  if (field.name === "phone") {
    return <PhoneNumberField field={field} />;
  }

  return <TextField field={field} />;
};

export default FormField;
