const updateApplicationInput = ({
  name,
  atsQuestionId,
  value,
  error,
  showValidation,
  dispatch,
}) => {
  dispatch({
    type: "UPDATE_APPLICATION_INPUT",
    payload: { name, atsQuestionId, value, showValidation, error },
  });
  const key = `${name || atsQuestionId}`;
  sessionStorage.setItem(key, value);
};

export default updateApplicationInput;
