import * as Sentry from "@sentry/browser";

const notifyError = (error) => {
  Sentry.captureException(error);
  console.log(error);
  return;
  fetch("/api/error", {
    method: "POST",
    body: JSON.stringify({ errorStack: error.stack }),
    headers: { "Content-Type": "application/json" },
  });
};

export default notifyError;
