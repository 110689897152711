import { h } from "preact";
import { useContext } from "preact/hooks";
import { route } from "preact-router";
import i18n from "i18next";
import { Store } from "../../store";
import { getPagePath, checkIfProfileFieldsAreValid } from "../../selectors";
import { getLabel } from "../../utils";

import Spinner from "../Spinner/Spinner";
import FormField from "../FormFields/FormField";
import CvField from "../FormFields/CvField";
import CoverLetterField from "../FormFields/CoverLetterField";
import { submitApplication } from "../../actionCreators";
import "./ProfileFormBlock.scss";

const ProfileFormBlock = () => {
  const { state, dispatch } = useContext(Store);
  const { application, form } = state;
  const { error, isSubmitting, isSubmitted, termsAccepted } = application;
  const { customer, jobPost } = form;
  const { questionFields, profileFields } = jobPost.applyForm;

  let termsHtml;
  switch (customer.slug) {
    case "adway": {
      termsHtml = i18n.t("form.termsHintAdway");
      break;
    }
    case "peab": {
      termsHtml = i18n.t("form.termsHintPeab");
      break;
    }
    default: {
      termsHtml = i18n.t("form.termsHint", {
        company: customer.name,
        tcUrl: customer.termsAndConditionsUrl,
      });
    }
  }

  const isInputValid = checkIfProfileFieldsAreValid(state);
  // If there are no questionFields there is no step 2 so we submit the form from here
  const isLastStep = !questionFields.length;

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isInputValid && !isSubmitted) {
      if (isLastStep) {
        submitApplication(state, dispatch);
      } else {
        route(`${getPagePath(state)}/questions`);
      }
    } else {
      profileFields.forEach((field) => {
        dispatch({ type: "SHOW_VALIDATION", payload: { name: field.name } });
      });
    }
  };

  const handleClick = (event) => {
    dispatch({ type: "UPDATE_TERMS_ACCEPTED", payload: event.target.checked });
  };

  const isButtonDisabled =
    isSubmitted || isSubmitting || !isInputValid || !termsAccepted;

  return (
    <div className="form-screen__block profile-form-block">
      <h2>{i18n.t("applyForm.formTitle")}</h2>
      <form onSubmit={handleSubmit} noValidate>
        {profileFields.map((field) => {
          if (field.name === "cv") {
            return <CvField />;
          }
          if (field.name === "coverLetter") {
            return <CoverLetterField />;
          }
          return (
            <FormField field={{ ...field, label: getLabel(field.name) }} />
          );
        })}
        {profileFields.find((field) => field.name === "cv") ? null : (
          <CvField />
        )}
        {profileFields.find((field) => field.name === "coverLetter") ? null : (
          <CoverLetterField />
        )}
        <div className="form-screen__button-wrapper">
          <button
            type="submit"
            className={`form-screen__button ${isButtonDisabled &&
              "form-screen__button--disabled"}`}
          >
            {(() => {
              if (!isLastStep) return i18n.t("form.buttons.continue");
              if (isSubmitting) return <Spinner />;
              return i18n.t("form.buttons.submit");
            })()}
          </button>
        </div>
        {isSubmitted && (
          <div className="form-screen__error">
            <p className="form-screen__error-text">
              {i18n.t("form.alreadySubmitted")}
            </p>
          </div>
        )}
        {error && (
          <div className="form-screen__error">
            <p className="form-screen__error-text">
              {i18n.t("form.submitFailed", { error })}
            </p>
          </div>
        )}
        <div
          className={`profile-form-block__tos ${
            customer.slug === "peab" ? "profile-form-block__normal_align" : ""
          } `}
        >
          {customer.slug === "peab" && (
            <div className="profile-form-block__left">
              <input type="checkbox" onChange={handleClick} />
            </div>
          )}
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <div
            dangerouslySetInnerHTML={{
              __html: termsHtml,
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default ProfileFormBlock;
