import { h } from "preact";
import i18n from "i18next";

const AvatarVariant = ({
  avatar,
  selectedAvatar,
  setSelectedAvatar,
  avatarTypeId,
  network
}) => {
  return (
    <label htmlFor={avatarTypeId} className="popup__profile">
      <input
        className="popup__radio"
        type="radio"
        id={avatarTypeId}
        checked={selectedAvatar === avatarTypeId}
        onClick={() => setSelectedAvatar(avatarTypeId)}
      />
      <span className="popup__profile-image">
        <img src={avatar} alt="" />
      </span>
      {selectedAvatar === avatarTypeId ? (
        <p className="popup__profile-text popup__profile-text--selected">
          {network === "storage"
            ? i18n.t("photoPopup.usingPhotoStorage")
            : i18n.t("photoPopup.usingPhotoNetwork", { network })}
        </p>
      ) : (
        <p className="popup__profile-text">
          {network === "storage"
            ? i18n.t("photoPopup.usePhotoStorage")
            : i18n.t("photoPopup.usePhotoNetwork", { network })}
        </p>
      )}
      <span className="popup__checked-status" />
    </label>
  );
};

export default AvatarVariant;
