import { h } from "preact";
import { useEffect, useContext } from "preact/hooks";
import { Store } from "../../store";
import { trackInitiatedCheckout } from "../../tracking";
import FormWrapper from "../../components/FormWrapper/FormWrapper";
import ProfileFormBlock from "../../components/ProfileFormBlock/ProfileFormBlock";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import ConnectWith from "../../components/ConnectWith/ConnectWith";
import AvatarSelector from "../../components/AvatarSelector/AvatarSelector";
import "./ProfileFormPage.scss";

const ProfileFormPage = () => {
  const { state } = useContext(Store);

  const { tracking } = state;
  useEffect(() => {
    if (tracking.isInitialized) {
      trackInitiatedCheckout(state.form.customer);
    }
  }, [tracking.isInitialized]);
  return (
    <FormWrapper>
      <ProgressBar step={1} />
      <ConnectWith />
      <ProfileFormBlock />
    </FormWrapper>
  );
};

export default ProfileFormPage;
