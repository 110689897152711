import { readFileAsBase64 } from "../utils";

const saveCoverLetter = ({ file, filename, dispatch }) => {
  dispatch({
    type: "UPDATE_COVER_LETTER",
    payload: { file, filename }
  });
  readFileAsBase64(file).then(fileBase64 => {
    sessionStorage.setItem("coverLetterFile", fileBase64);
    sessionStorage.setItem("coverLetterFilename", filename);
  });
};

export default saveCoverLetter;
