import { h } from "preact";
import i18n from "i18next";
import { fileExtensions, maxFileSize } from "../../config";
import { notifyError } from "../../utils";
import uploadIconSvg from "../../../assets/images/uploadIcon.svg";

const UploadStorage = ({
  handleUpload,
  handleError,
  fieldName,
  avatarTypeId,
}) => {
  const handleInput = (event) => {
    const file = event.target.files[0] ?? {};
    if (file.size > maxFileSize) {
      handleError(
        new Error(
          i18n.t("form.validation.maxFileSize", {
            maxSizeInMb: maxFileSize / (1024 * 1024),
          })
        )
      );
    } else if (file.size === 0) {
      handleError(new Error(i18n.t("form.validation.emptyFile")));
      notifyError(new Error(`User tried to upload empty file`));
    } else {
      handleUpload({ file, filename: file.name, avatarTypeId });
    }
  };

  // Join array into comma-separated string
  const accept = fileExtensions[fieldName].join();
  const ua = navigator.userAgent || navigator.vendor || window.opera;
  const isFacebookBrowser =
    ua.indexOf("FBAN") > -1 ||
    ua.indexOf("FBAV") > -1 ||
    ua.indexOf("Instagram") > -1;

  return (
    <label htmlFor="file-upload" className="popup__upload-item">
      <input
        type="file"
        id="file-upload"
        onChange={handleInput}
        accept={!isFacebookBrowser ? accept : undefined}
      />
      <div className="popup__upload-icon">
        <img src={uploadIconSvg} alt="" />
      </div>
      <p className="popup__upload-text">{i18n.t("popup.uploadFromStorage")}</p>
    </label>
  );
};

export default UploadStorage;
