import { h, Fragment } from "preact";
import { useEffect } from "preact/hooks";
import i18n from "i18next";
import { Title, Link } from "react-head";
import "./NotFoundPage.scss";

const NotFoundPage = () => {
  useEffect(() => {
    if (navigator.language) {
      i18n.changeLanguage(navigator.language);
    }
  }, []);

  return (
    <Fragment>
      <Title>Adway Apply</Title>
      <Link rel="icon" type="image/png" href="/favicons/adway-32x32.png" />
      <div className="notFound__container">
        <div className="notFound">
          <h1 className="notFound__heading">{i18n.t("notFound.heading")}</h1>
          <h2 className="notFound__title">{i18n.t("notFound.title")}</h2>
          <span className="notFound__text">{i18n.t("notFound.text-1")}</span>
        </div>
      </div>
    </Fragment>
  );
};

export default NotFoundPage;
