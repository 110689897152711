import { h } from "preact";
import { useContext } from "preact/hooks";
import { Store } from "../../store";
import { validateField, countries, getFormFieldClass } from "../../utils";
import { updateApplicationInput } from "../../actionCreators";
import { HIGHLIGHTED_COUNTRIES } from "./PhoneNumberField";

const CountrySelectField = ({ field }) => {
  const { state, dispatch } = useContext(Store);
  const { answers, profileInfo } = state.application;
  const fieldData = answers[field.atsQuestionId] || profileInfo[field.name];

  const handleChange = (event) => {
    const { value } = event.target;

    const error = validateField({ value, validation: field.validation });
    updateApplicationInput({
      name: field.name,
      atsQuestionId: field.atsQuestionId,
      value,
      error,
      dispatch,
    });

    dispatch({
      type: "SHOW_VALIDATION",
      payload: { name: field.name, atsQuestionId: field.atsQuestionId },
    });
  };

  const sortByAscendingCountryCode = (a, b) =>
    Number(a.countryCode.replace("+", "")) <
    Number(b.countryCode.replace("+", ""))
      ? -1
      : 1;

  const getSortedCountries = () => {
    const highlightedCountries = countries
      .filter((c) =>
        HIGHLIGHTED_COUNTRIES.includes(c.name?.toLocaleLowerCase())
      )
      ?.sort(sortByAscendingCountryCode);

    return highlightedCountries
      .concat({})
      .concat(countries.filter((c) => !highlightedCountries.includes(c)));
  };

  const formFieldClass = getFormFieldClass(fieldData);

  return (
    <div className={`form-field ${formFieldClass}`}>
      <span className="form-field__label">{field.label}</span>
      <select onChange={handleChange} value={fieldData.value}>
        {getSortedCountries().map(({ name, code }) =>
          name ? <option value={code}>{name}</option> : <hr />
        )}
      </select>
      {fieldData.showValidation && (
        <p className="form-field__error">{fieldData.error}</p>
      )}
    </div>
  );
};

export default CountrySelectField;
