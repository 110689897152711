/* eslint-disable react/no-danger */
import { h, Fragment } from "preact";
import { useContext } from "preact/hooks";
import { Title, Link, Meta } from "react-head";
import { Store } from "../store";

const Head = () => {
  const { state } = useContext(Store);
  const { jobPost, customer } = state.form;

  return (
    <Fragment>
      <Link rel="icon" type="image/png" href={customer.faviconUrl} />
      <Title>{`${jobPost.title} - ${customer.name}`}</Title>
      <Meta property="og:type" content="website" />
      {jobPost.isInternalPosition && (
        <Meta name="robots" content="noindex, nofollow" />
      )}

      <Meta
        property="og:title"
        content={`${jobPost.title} - ${customer.name}`}
      />
      <Meta property="og:image" content={jobPost.images.backgroundSmall} />
      <Meta property="og:description" content={jobPost.description} />
    </Fragment>
  );
};

export default Head;
