import { h, Fragment } from "preact";
import { useState } from "preact/hooks";
import fetch from "node-fetch";
import emailSvg from "../../../assets/images/email.svg";
import Spinner from "../Spinner/Spinner";


const EmailRedirectForm = ({ jobPost }) => {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(null);
  const [showValidationError, setShowValidationError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isEmailValid) {
      setIsSubmitting(true);
      const body = JSON.stringify({ email });
      fetch(`/api/emailApplication/${jobPost._id}`, {
        method: "POST",
        body,
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          if (!res.ok) {
            return res.text().then((message) => {
              throw new Error(message);
            });
          }
          return res;
        })
        .then((res) => res.json())
        .then(() => {
          window.location.href = jobPost.applicationUrl || jobPost.url;
        });
    } else {
      setShowValidationError(true);
    }
  };

  const handleInput = (event) => {
    setEmail(event.target.value);
    const isValid = /\S+@\S+\.\S+/.test(event.target.value);
    setIsEmailValid(isValid);
  };

  return (
    <form onSubmit={handleSubmit} className="redirect-email-wrapper">
      <div className="redirect-email">
        <img src={emailSvg} alt="" className="redirect-email-symbol" />
        <input
          type="text"
          className="redirect-email-field mobile-only"
          value={email}
          onInput={handleInput}
          placeholder="Your email"
        />
        <input
          type="text"
          className="redirect-email-field desktop-only"
          value={email}
          onInput={handleInput}
          placeholder="Enter your email"
        />
        <button
          type="submit"
          className="form-screen__button redirect-email-button mobile-only"
        >
          {isSubmitting ? (
            <Spinner />
          ) : (
            <Fragment>
              Apply now <span className="apply-arrow">➜</span>
            </Fragment>
          )}
        </button>
        <button
          type="submit"
          className="form-screen__button redirect-email-button desktop-only"
        >
          {isSubmitting ? (
            <Spinner />
          ) : (
            <Fragment>
              Apply for job <span className="apply-arrow">➜</span>
            </Fragment>
          )}
        </button>
      </div>
      {showValidationError && !isEmailValid && (
        <p className="redirect-email-error">Please fill in a valid email</p>
      )}
    </form>
  );
};

export default EmailRedirectForm