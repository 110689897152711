import queryString from "query-string";

const LinkedInAuth = ({ render, redirectUrl }) => {
  const handleClick = event => {
    event.preventDefault();
    // Set redirectUrl in session storage to be able to get back to the right page after successful authentication
    sessionStorage.setItem("linkedInRedirectUrl", redirectUrl);
    // Redirect to special front-end redirection page
    const redirectUri = `${window.location.protocol}//${window.location.host}/linkedInAuth`;
    const query = queryString.stringify({
      response_type: "code",
      client_id: process.env.LINKEDIN_CLIENT_ID,
      redirect_uri: redirectUri,
      scope: "r_basicprofile r_emailaddress",
      // Unique but not secret string
      state: "jqoiASDYmxcvadsfiuhxzcvSDYUbqwdjhb"
    });
    const url = `https://www.linkedin.com/oauth/v2/authorization?${query}`;
    window.location.href = url;
  };

  return render({ onClick: handleClick });
};

export default LinkedInAuth;
