const readBase64UrlAsBlob = base64Url => {
  const bytes = base64Url.split(",")[1];
  if (!bytes) {
    return;
  }
  const byteString = atob(bytes);

  // separate out the mime component
  const mimeType = base64Url
    .split(",")[0]
    .split(":")[1]
    .split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  const u8Arr = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    u8Arr[i] = byteString.charCodeAt(i);
  }

  return new Blob([u8Arr], { type: mimeType });
};

export default readBase64UrlAsBlob;
