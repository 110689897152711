import i18n from "i18next";
import en from "./translations/en.json";
import fi from "./translations/fi.json";
import nb from "./translations/nb.json";
import sv from "./translations/sv.json";

i18n.init({
  fallbackLng: "en",
  lng: "en",
  debug: false,
  interpolation: {
    escapeValue: false
  },
  resources: {
    en: { translations: en },
    fi: { translations: fi },
    nb: { translations: nb },
    sv: { translations: sv }
  },
  react: {
    wait: true
  },
  ns: ["translations"],
  defaultNS: "translations"
});

export default i18n;
