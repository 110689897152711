import { h } from "preact";
import TitleBlock from "../TitleBlock/TitleBlock";
import "./FormWrapper.scss";

const FormWrapper = ({ children }) => {
  // TODO: Remove unnecessary nested divs
  return (
    <div className="form-screen">
      <div className="container">
        <TitleBlock />
        <div className="form-screen__form-section">
          <div className="form-screen__content">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default FormWrapper;
