import { h } from "preact";
import { useContext, useState } from "preact/hooks";
import { Store } from "../../store";
import { validateField, countries, getLabel } from "../../utils";
import { updateApplicationInput } from "../../actionCreators";
import i18n from "../../i18n";

export const HIGHLIGHTED_COUNTRIES = ["sweden", "norway", "finland"];
const COUNTRY_CODE = "countryCode";
const PHONE_NUMBER = "displayedPhoneNumber";

const PhoneNumberField = ({ field }) => {
  const { state, dispatch } = useContext(Store);
  const { profileInfo } = state.application;
  const [selectedCountryCode, setSelectedCountryCode] = useState(
    () => profileInfo.countryCode?.value
  );
  const [phoneNumber, setPhoneNumber] = useState(
    () => profileInfo.displayedPhoneNumber?.value
  );

  const handlePhoneNumberChange = (event) => {
    const val = event.target.value;
    const error = validateField({
      value: val,
      validation: { ...field.validation, pattern: "\\d{6,15}" },
    });

    setPhoneNumber(val);
    updateStore(PHONE_NUMBER, val, error);
  };

  const handleCountryCodeChange = (event) => {
    const val = event.target.value;
    setSelectedCountryCode(val);
    updateStore(COUNTRY_CODE, val, false);
    handleBlur(COUNTRY_CODE);
  };

  const updateStore = (name, value, error) => {
    updateApplicationInput({
      name,
      value,
      error,
      dispatch,
    });
  };

  const handleBlur = (name) => {
    dispatch({
      type: "SHOW_VALIDATION",
      payload: {
        name,
      },
    });
  };

  const sortByAscendingCountryCode = (a, b) =>
    Number(a.countryCode.replace("+", "")) <
    Number(b.countryCode.replace("+", ""))
      ? -1
      : 1;

  const getSortedCountries = () => {
    const highlightedCountries = countries
      .filter((c) =>
        HIGHLIGHTED_COUNTRIES.includes(c.name?.toLocaleLowerCase())
      )
      .sort(sortByAscendingCountryCode);

    return highlightedCountries
      .concat({})
      .concat(
        countries
          .filter((c) => !highlightedCountries.includes(c))
          .sort(sortByAscendingCountryCode)
      );
  };

  const getValidationClass = (showValidation, error) => {
    if (showValidation) {
      return error ? "not-valid" : "validated";
    }
    return "";
  };

  const formFieldClassCountryCode = getValidationClass(
    profileInfo.countryCode?.showValidation,
    !selectedCountryCode
  );
  const formFieldClassPhone = getValidationClass(
    profileInfo.displayedPhoneNumber?.showValidation,
    profileInfo.displayedPhoneNumber?.error
  );

  return (
    <div className="form-field phone-field">
      <div className={`form-field ${formFieldClassCountryCode} country-code`}>
        <span className="form-field__label">{getLabel(COUNTRY_CODE)}</span>
        <select
          id={COUNTRY_CODE}
          name={COUNTRY_CODE}
          onChange={handleCountryCodeChange}
          onBlur={() => handleBlur(COUNTRY_CODE)}
          value={selectedCountryCode}
          onFocus={() => handleBlur(COUNTRY_CODE)}
        >
          {getSortedCountries().map(({ countryCode }) =>
            countryCode ? (
              <option
                className="option-description"
                value={countryCode}
                label={countryCode}
                data-description={`${countryCode}`}
              />
            ) : (
              <hr />
            )
          )}
        </select>
        {formFieldClassCountryCode === "not-valid" && (
          <p className="form-field__error">
            {i18n.t("form.validation.required")}
          </p>
        )}
      </div>
      <div className={`form-field ${formFieldClassPhone} phone`}>
        <label htmlFor="displayedNumber" className="form-field__label">
          {field.label}
        </label>
        <input
          value={phoneNumber}
          id="displayedNumber"
          name="displayedNumber"
          type="text"
          pattern="\d{6,15}"
          onChange={handlePhoneNumberChange}
          onBlur={() => handleBlur(PHONE_NUMBER)}
        />
        {profileInfo.displayedPhoneNumber?.showValidation && (
          <p className="form-field__error">
            {profileInfo.displayedPhoneNumber?.error}
          </p>
        )}
      </div>
    </div>
  );
};

export default PhoneNumberField;
