import { h } from "preact";
import { useState } from "preact/hooks";
import i18n from "i18next";
import * as Sentry from "@sentry/browser";
import UploadGoogleDrive from "../UploadGoogleDrive/UploadGoogleDrive";
import UploadDropbox from "../UploadDropbox/UploadDropbox";
import UploadStorage from "../UploadStorage/UploadStorage";
import "./CvPopup.scss";

const CvPopup = ({ closePopupWithTransition, updateCv, isForm = false }) => {
  const [fileError, setFileError] = useState(null);

  const handleUpload = ({ file, filename }) => {
    updateCv({ file, filename });
    closePopupWithTransition(false);
  };

  const handleError = (error) => {
    Sentry.captureException(error);
    setFileError(error.message);
  };

  return (
    <div className={`${isForm ? "popup-cv--form" : ""} popup-cv`}>
      <button
        className="popup-cv__close"
        onClick={() => closePopupWithTransition(false)}
        type="button"
      >
        ✕
      </button>
      <div className="popup__content">
        {!fileError ? (
          <div className="popup-cv__content">
            <h3 className="popup__title">
              {i18n.t("cvPopup.title")}
              <small>
                <br />
                {i18n.t("cvPopup.supportedFileFormats")}
              </small>
            </h3>
            <div className="popup__upload show">
              <UploadDropbox
                handleUpload={handleUpload}
                handleError={handleError}
                fieldName="cv"
              />
              <UploadGoogleDrive
                handleUpload={handleUpload}
                handleError={handleError}
                fieldName="cv"
              />
              <UploadStorage
                handleUpload={handleUpload}
                handleError={handleError}
                fieldName="cv"
              />
            </div>
          </div>
        ) : (
          <div className="popup-cv__error">
            <h3 className="popup__title">{i18n.t("cvPopup.errorTitle")}</h3>
            <p className="error__text">{fileError}</p>
            <button
              type="button"
              className="popup__tab-button"
              onClick={() => setFileError(null)}
            >
              {i18n.t("cvPopup.uploadAnother")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CvPopup;
