import { readFileAsBase64 } from "../utils";

const saveAvatar = ({ id, file, filename, thumbnail, dispatch }) => {
  dispatch({
    type: "UPDATE_AVATAR_DATA",
    payload: { id, thumbnail, file, filename }
  });
  readFileAsBase64(file).then(avatarBase64 => {
    sessionStorage.setItem("avatarId", id);
    sessionStorage.setItem(`avatar-${id}`, avatarBase64);
    sessionStorage.setItem(`avatarFilename-${id}`, filename);
  });
};

export default saveAvatar;
