import { h } from "preact";
import ProfileFormButton from "../../components/ProfileFormButton/ProfileFormButton";
import FormWrapper from "../../components/FormWrapper/FormWrapper";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import QuestionFormBlock from "../../components/QuestionFormBlock/QuestionFormBlock";

const QuestionFormPage = () => (
  <FormWrapper>
    <ProgressBar step={2} />
    <ProfileFormButton />
    <QuestionFormBlock />
  </FormWrapper>
);

export default QuestionFormPage;
