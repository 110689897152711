import { h } from "preact";
import { useContext } from "preact/hooks";
import i18n from "i18next";
import AnimatedNumber from "react-animated-number";
import { Store } from "../../store";

import emptyPhotoSvg from "../../../assets/images/emptyPhoto.svg";

const ProfileSummary = ({ profileStrengthPercentage }) => {
  const { state } = useContext(Store);
  const { avatars, confirmedAvatar } = state.avatar;
  const currentAvatarData = avatars[confirmedAvatar];

  const thumbnail = currentAvatarData
    ? currentAvatarData.thumbnail
    : emptyPhotoSvg;

  const {
    profileInfo: { name, firstName, lastName }
  } = state.application;
  const fullName = name ? name.value : `${firstName.value} ${lastName.value}`;

  return (
    <div className="profile__info">
      <div className="profile__info-wrapper">
        <div className={`photo${currentAvatarData ? " withPhoto" : ""}`}>
          <div className="photo__container">
            <img src={thumbnail} alt="" />
          </div>
        </div>
        <h2 className="name">{fullName}</h2>
        <span className="percentage">
          <AnimatedNumber
            component="i"
            value={profileStrengthPercentage}
            stepPrecision={0}
            formatValue={n => `${n}%`}
            duration={400}
          />
        </span>
        <span className="strength">{i18n.t("confirmation.profileStrength")}</span>
      </div>
    </div>
  );
};

export default ProfileSummary;
