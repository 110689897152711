import { h } from "preact";
import i18n from "i18next";
import { getCurrentUrl, route } from "preact-router";
import "./ProfileFormButton.scss";

const ProfileFormButton = () => {
  const goBack = () => {
    const currentUrl = getCurrentUrl();

    const newUrl = `${currentUrl.slice(0, currentUrl.lastIndexOf("/"))}/apply`;
    route(newUrl);
  };
  return (
    <button className="profile-form-button" onClick={goBack}>
      {i18n.t(`form.buttons.backToProfile`)}
    </button>
  );
};
export default ProfileFormButton;
