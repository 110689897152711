import { h } from "preact";
import { useContext } from "preact/hooks";
import { Store } from "../../store";
import { validateField, getFormFieldClass } from "../../utils";
import { updateApplicationInput } from "../../actionCreators";

const TextField = ({ field }) => {
  const { state, dispatch } = useContext(Store);
  const { answers, profileInfo } = state.application;

  const fieldId = field.atsQuestionId || field.name;
  const fieldData = answers[field.atsQuestionId] || profileInfo[field.name];

  const handleInput = (event) => {
    const value =
      field.type === "number" ? Number(event.target.value) : event.target.value;
    const error = validateField({ value, validation: field.validation });
    updateApplicationInput({
      name: field.name,
      atsQuestionId: field.atsQuestionId,
      value,
      error,
      dispatch,
    });
  };
  const handleBlur = () => {
    dispatch({
      type: "SHOW_VALIDATION",
      payload: { name: field.name, atsQuestionId: field.atsQuestionId },
    });
  };

  const formFieldClass = getFormFieldClass(fieldData);
  const label = field.unit ? `${field.label} (${field.unit})` : field.label;
  return (
    <div className={`form-field ${formFieldClass}`}>
      <label htmlFor={fieldId} className="form-field__label">
        {label}
      </label>
      <input
        value={fieldData.value}
        placeholder={
          field.type === "number"
            ? 0
            : label +
              (field.customPlaceholder ? ` ${field.customPlaceholder}` : "")
        }
        id={fieldId}
        name={fieldId}
        type={field.type}
        onInput={handleInput}
        onBlur={handleBlur}
      />
      {fieldData.showValidation && (
        <p className="form-field__error">{fieldData.error}</p>
      )}
    </div>
  );
};

export default TextField;
