import i18n from "../i18n";

const validateField = ({ value, validation }) => {
  if (!validation) {
    return false;
  }
  const {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    pattern,
  } = validation;

  if (required && (value == null || value === "")) {
    return i18n.t("form.validation.required");
  }
  if (minLength && value.length < minLength) {
    return i18n.t("form.validation.minLength", { minLength });
  }
  if (maxLength && value.length > maxLength) {
    return i18n.t("form.validation.maxLength", { maxLength });
  }
  if (minValue != null && value < minValue) {
    return i18n.t("form.validation.minValue", { minValue });
  }
  if (maxValue != null && value > maxValue) {
    return i18n.t("form.validation.maxValue", { maxValue });
  }

  if (pattern) {
    const regexp = new RegExp(pattern, "g");
    if (typeof value === "string") {
      const match = value.match(regexp);
      if (!match || match[0] !== value) {
        return i18n.t("form.validation.matches");
      }
    }
  }
  return false;
};

export default validateField;
